<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <!-- <v-tabs color="black" v-model="tabs" show-arrows>
        <v-tab class="fonte-bold">Receb. Bonificação</v-tab>
        <v-tab class="fonte-bold">Ped. Saque</v-tab>
        <v-tab class="fonte-bold">Depósito</v-tab>
        <v-tab class="fonte-bold">Compras</v-tab>
        <v-tab class="fonte-bold">Transferências</v-tab>
      </v-tabs> -->
      <v-flex xs12>
        <div class="expande-horizontal centraliza">
          <v-btn
            :text="filterDate !== 'all'"
            @click="setFilterDate('all')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            Todos
          </v-btn>
          <v-btn
            :text="filterDate !== '7'"
            @click="setFilterDate('7')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            7 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '15'"
            @click="setFilterDate('15')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            15 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '30'"
            @click="setFilterDate('30')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            30 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '60'"
            @click="setFilterDate('60')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            60 dias
          </v-btn>
          <v-btn
            :text="filterDate !== '90'"
            @click="setFilterDate('90')"
            color="black"
            dark
            class="fonte-bold mr-1"
          >
            90 dias
          </v-btn>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="expande-horizontal">
          <v-list style="min-width: 100%;" class="pa-0 ma-0" three-line>
            <template v-for="(item, index) in getTransactionsFiltered">
              <v-list-item :key="item._id">
                <v-list-item-content>
                  <v-list-item-title class="fonte-bold">
                    {{ item.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    {{ $helper.formataSaldo(item.value) }}
                    <v-chip
                      small
                      :color="getStatus(item.current_status).color"
                      >{{ getStatus(item.current_status).text }}</v-chip
                    ></v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="font-weight-light"
                    style="font-size: 8pt;"
                  >
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </div>
      </v-flex>

      <Empty v-if="getTransactions.length === 0"></Empty>
      <!-- <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>Transação</v-card-title>
          <v-card-text ref="transactionContent">
            <div>ID da Transação: {{ transaction._id }}</div>
            <div>ID do Usuário: {{ transaction.user_id }}</div>
            <div>Tipo de Transação: {{ transaction.transaction_type }}</div>
            <div>Descrição: {{ transaction.description }}</div>
            <div>Valor: {{ transaction.value }}</div>
            <div>Taxa: {{ transaction.fee }}</div>
            <div>Status Atual: {{ transaction.current_status }}</div>
            <div>Data de Criação: {{ formatDate(transaction.created_at) }}</div>
            <div>
              Data de Desbloqueio: {{ formatDate(transaction.unlock_date) }}
            </div>
            <div>
              Saque Pendente: {{ transaction.withdraw ? "Sim" : "Não" }}
            </div>
            <div>
              Pagamento de Saque:
              {{ transaction.withdraw_paid ? "Sim" : "Não" }}
            </div>
            <div>Status: {{ transaction.status.join(", ") }}</div>
            <div>
              NSU Pinbank: {{ parseExternalData(transaction.external_data) }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="captureImage">Compartilhar no WhatsApp</v-btn>
            <v-btn @click="close">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getTransactions"]),
    getTransactionsFiltered() {
      if (this.filterDate === "all" || !this.filterDate) {
        return this.getTransactions;
      }

      const now = this.$moment(); // Defina o momento atual uma única vez
      const filterByDays = days => {
        return this.getTransactions.filter(item => {
          let created_at = this.$moment(item.created_at);
          let daysInPast = now.clone().subtract(days, "days");
          return created_at.isBetween(daysInPast, now, null, "[]"); // Inclusivo de início e fim
        });
      };

      switch (this.filterDate) {
        case "7":
          return filterByDays(7);
        case "15":
          return filterByDays(15);
        case "30":
          return filterByDays(30);
        case "60":
          return filterByDays(60);
        case "90":
          return filterByDays(90);
        default:
          return this.getTransactions;
      }
    }
  },
  data() {
    return {
      filterDate: "all",
      dialog: false,
      transaction: {}
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    captureImage() {
      const element = this.$refs.transactionContent;
      // html2canvas(element).then(canvas => {
      //   const imageUrl = canvas.toDataURL("image/png");

      //   // Criar um link para compartilhar no WhatsApp
      //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent('Confira a transação:')}&url=${encodeURIComponent(imageUrl)}`;
        
      //   window.open(whatsappUrl, '_blank');
      // });
    },
    formatDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    },
    parseExternalData(externalData) {
      try {
        const data = JSON.parse(externalData);
        return data.Data.NSUPinbank || 'N/A';
      } catch (error) {
        return 'N/A';
      }
    },
    ViewTransition(item) {
      this.dialog = true;
      this.transaction = item;
    },
    ...mapActions(["listTransactions"]),
    setFilterDate(date) {
      this.filterDate = date;
    },
    getStatus(status) {
      switch (status) {
        case "waiting":
          return {
            text: "Aguardando",
            color: "orange"
          };
        case "active":
          return {
            text: "Disponível",
            color: "green"
          };
        case "promise":
          return {
            text: "Não consolidada",
            color: "red"
          };
        default:
          return {
            text: "Outros",
            color: "grey"
          };
      }
    }
  },
  created() {
    this.listTransactions({ all: true });
  }
};
</script>
