<template>
  <v-list
    class="pa-0 ma-0 fonte pt-1"
    dense
    :dark="$theme.dark"
    :light="$theme.light"
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="(funcionario, i) in filtereds">
      <v-list-item
        @click="open(funcionario)"
        style="border-radius: 6px;"
        :key="funcionario._id"
        class="fonte pl-1 pr-1"
      >
        <v-list-item-content class="ml-1">
          <v-list-item-title>
            <span class="fonte">
              {{ funcionario.name }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="fonteMini">
            <v-chip
              small
              :style="`color: ${$theme.primary}; border-radius: 3px;`"
            >
              <v-icon :color="$theme.primary" size="11" class="mr-2"
                >mdi-card-account-details-outline</v-icon
              >
              {{ funcionario.cpf }}
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <span class="fonteMini">
              {{ funcionario.email }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar rounded size="36" color="#f2f2f2">
            <v-icon :color="$theme.primary">mdi-account-circle-outline</v-icon>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="i"></v-divider>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["get_funcionario"])
  },
  methods: {
    open(client) {
      let c = client;
      if (!c.profile_permission) {
        c.profile_permission = {};
      }
      this.$store.commit("set_funcionario", c);
    }
  }
};
</script>
