<template>
  <v-app light id="app">
    <router-view />
    <ModalConfirmAction />
    <ModalLoading />
    <ModalGlobalMessage />
  </v-app>
</template>

<script>
import ModalConfirmAction from "@/apps/shared/components/ModalConfirmAction";
import ModalLoading from "@/apps/shared/components/ModalLoading";
import ModalGlobalMessage from "@/apps/shared/components/ModalGlobalMessage";

export default {
  components: {
    ModalConfirmAction,
    ModalLoading,
    ModalGlobalMessage
  },
};
</script>

<style>
.img-logo {
  filter: invert(97%);
}
@media (prefers-color-scheme: dark) {
  body {
    background: #333;
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background: #333;
    color: white;
  }
}
.windows-style-bottom {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #e0e0e0;
  border-top: none;
  background: #f2f2f2;
  padding-right: 6px;
  padding-left: 6px;
}
.bg-theme {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.fonteMini {
  font-size: 8pt;
}
.fonte-mini {
  font-size: 8pt;
}
.click {
  cursor: pointer;
}
.item-table {
  border-radius: 6px;
  border: 1px solid #e2e2e2;
}
.bg-table {
  background: #f2f2f2;
}
.column {
  flex-direction: column;
}
.bg_white {
  background: #fff;
}
.expande-horizontal {
  display: flex;
  width: 100%;
}
.item-list-title {
  padding: 6px;
  background: #e2e2e2;
  border-radius: 6px;
  margin-bottom: 6px;
}
.inflar {
  display: flex;
  width: 100%;
  min-height: 100vh;
}
.inflar-porcentagem {
  display: flex;
  width: 100%;
  height: 100%;
}
.centraliza {
  justify-content: center;
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.linha {
  flex-direction: row;
}
.fonte-default {
  font-family: "Prompt", sans-serif;
}
/* @font-face {
  font-family: Sui Generis;
  src: url("/fonts/suigenerisrg.otf") format("opentype");
} */
@font-face {
  font-family: "Myriad Pro Light";
  src: url("/fonts/myriadpro-light.otf") format("opentype");
  /* src: url('/fonts/myriadpro-light.otf') format('otf'), */
  /* url('/fonts/myriadpro-light.otf') format('otf'); */
  /* Adicione outros atributos conforme necessário, como font-weight e font-style */
}
@font-face {
  font-family: "Myriad Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"),
    url("/fonts/MYRIADPRO-REGULAR.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed"),
    url("/fonts/MYRIADPRO-COND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Condensed Italic"),
    url("/fonts/MYRIADPRO-CONDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Light"),
    url("/fonts/MyriadPro-Light.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold"),
    url("/fonts/MYRIADPRO-SEMIBOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Semibold Italic"),
    url("/fonts/MYRIADPRO-SEMIBOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed"),
    url("/fonts/MYRIADPRO-BOLDCOND.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold"),
    url("/fonts/MYRIADPRO-BOLD.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Italic"),
    url("/fonts/MYRIADPRO-BOLDIT.woff") format("woff");
}

@font-face {
  font-family: "Myriad Pro Bold Condensed Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Bold Condensed Italic"),
    url("/fonts/MYRIADPRO-BOLDCONDIT.woff") format("woff");
}
.fonte-italica {
  font-style: italic;
}
.fonte-big {
  font-size: 48px;
}
.fonte-medium {
  font-size: 36px;
}
.fonte-little {
  font-size: 24px;
}
.fonte-mini {
  font-size: 16px;
}
.fonte-micro {
  font-size: 12px;
}
/* .fonte {
  font-family: "Prompt", sans-serif;
} */

.fonte {
  font-family: "Myriad Pro Regular";
}
.font-color {
  color: #363636;
}
.font-color--green {
  color: #47cf72;
}
.font-color--secondary {
  color: #1173ff;
}
.fonte-bold {
  font-family: "Myriad Pro Bold Condensed";
}
.item-contained {
  border-radius: 6px;
  padding: 12px;
  background: #333;
}
.expande-vh-vertical {
  height: 100vh;
}

.menuAtivoHome {
  background: linear-gradient(to right, #f12711, #f5af19);
  border-radius: 1px;
  color: #fff !important;
}
.menuAtivoHomeGold {
  background: #f12711; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f12711,
    #f5af19
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f12711, #f5af19);
}
.cardMesa {
  background: #1173ff; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #1173ff,
    #010a33
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1173ff, #010c3f);
}
.bg-card-dash {
  background: linear-gradient(#1173ff, #0020ab);
}
.item {
  height: 40px;
}
.vertical-60 {
  min-height: 60vh;
}
.vitrify {
  min-height: 100vh;
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(20px);
}
.vitrify-content {
  background: rgba(215, 215, 215, 0.1) !important;
  backdrop-filter: blur(10px) !important;
}
.vitro {
  background: rgba(215, 215, 215, 0.1);
  backdrop-filter: blur(10px);
}
.default-font-color {
  color: #1173ff;
}
.secondary-color {
  color: #e1673c;
}
.fonteMini {
  font-size: 8pt;
}
.windows-style {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f2f2f2;
  padding-right: 6px;
  padding-left: 6px;
}
.windows-style-content {
  border-bottom: 1px solid #e0e0e0;
  background: #f2f2f2;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}
.windows-style-content::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.windows-style-content::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.windows-style-content::-webkit-scrollbar-thumb {
  background-color: #707172; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
.cool-shaddow {
  box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px -2px 60px 2px rgba(0, 0, 0, 0.1);
}
.not-selectable {
  user-select: none;
  -webkit-user-select: none; /* Para compatibilidade com navegadores baseados em WebKit, como o Safari */
  -moz-user-select: none; /* Para compatibilidade com navegadores baseados em Gecko, como o Firefox */
  -ms-user-select: none; /* Para compatibilidade com o Internet Explorer */
}
.text-no-wrap {
  white-space: nowrap;
}
.signed-gradient {
  background-image: linear-gradient(
    to right,
    rgb(140, 85, 195),
    steelblue,
    rgb(52, 197, 182)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: black;
  user-select: none;
  -webkit-user-select: none; /* Para compatibilidade com navegadores baseados em WebKit, como o Safari */
  -moz-user-select: none; /* Para compatibilidade com navegadores baseados em Gecko, como o Firefox */
  -ms-user-select: none; /* Para compatibilidade com o Internet Explorer */
}
.dark-font {
  color: #363636;
}
</style>
