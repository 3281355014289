<template>
  <v-card
    outlined
    color="#e5e5e5"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span class="mb-3 fonte black--text" :style="`font-size: 12px;`">
        Conta Digital PJ
      </span>

      <div style="width: 100%" class="expande-horizontal mb-2 pb-1 pt-0">
        <v-chip
          style="width: 100%;"
          color="#333"
          class="fonte-bold"
          dark
          @click="goToRegister"
        >
          Clique aqui e abra sua Conta PJ Revolution
        </v-chip>
        <button
          class="elevation-2 ml-2"
          icon
          @click="goToRegister"
          style="background-color: #333;border-radius: 50%;min-width: 40px; min-height: 40px; max-width: 40px; max-height: 40px;"
        >
          <v-icon color="#f2f2f2">mdi-open-in-new</v-icon>
        </button>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      myLink: ""
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    goToRegister() {
      window.open(
        "https://acessoaconta.com.br/Cadastro/scn/PessoaJuridica/eyJjb2RfcHJvZHV0byI6MjUxLCJ0aXBvIjoicGoifQ==",
        "_blank"
      );
    }
  },
  mounted() {
    this.linkMount();
  }
};
</script>
