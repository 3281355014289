export const investment_routes = [
  {
    path: "/investments",
    component: () => import("../Main.vue"),
    name: "Ativos de Investimento"
  },
  {
    path: "/my-investments",
    component: () => import("../UserMain.vue"),
    name: "Meus Ativos"
  },
];

export default {};
