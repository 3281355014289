export const dashboard_routes = [
  {
    path: "/dashboard",
    component: () => import("../Main.vue"),
    // component: () => import("../MainApk.vue"),
    name: "Dashboard"
  }
];

export default {};
