<template>
  <div
    class="inflar"
    style="opacity: 0.95;overflow: hidden; background: #f8f8f8;"
  >
    <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md6>
      <div
        class="inflar"
        style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/pagamento-de-tecnologia-facil-e-sem-fio-2-scaled.jpg');"
      ></div>
    </v-flex>
    <v-flex xs12 md6>
      <div
        class="inflar"
        style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/pagamento-de-tecnologia-facil-e-sem-fio-2-scaled.jpg');"
      >
        <div
          class="inflar centraliza elevation-4"
          :style="
            `overflow: hidden; background: ${
              $vuetify.breakpoint.smAndDown
                ? 'rgba(255,255,255,0.8)'
                : 'rgba(255,255,255,1)'
            };`
          "
        >
          <v-flex xs10 md6>
            <div class="inflar-porcentagem centraliza column">
              <FormLogin />
              <div v-if="!hiddenSignup" class="inflar-porcentagem centraliza">
                <v-btn
                  text
                  @click="$router.push('/nosponsor')"
                  class="fonte-bold"
                >
                  Crie sua conta agora
                </v-btn>
              </div>

              <div v-if="!hiddenSignup" class="inflar-porcentagem centraliza">
                <v-btn
                  text
                  small
                  @click="setModalForgotPassword(true)"
                  class="fonte-bold"
                >
                  Recuperar Senha
                </v-btn>
              </div>
            </div>
            <ForgotPassword />
          </v-flex>
        </div>
      </div>
    </v-flex>
  </div>
</template>

<script>
import FormLogin from "./components/FormLogin";
import ForgotPassword from "./components/ForgotPassword";
import FormSignup from "./components/FormSignup";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      random_um: 0,
      random_dois: 0,
      captcha: false,
      resultado: "",
      hiddenSignup: false
    };
  },
  components: {
    FormLogin,
    ForgotPassword,
    FormSignup
  },
  methods: {
    ...mapActions(["setModalForgotPassword", "createGlobalMessage"]),
  },
  mounted() {
    setTimeout(() => {
      if (!!window.PinBankPlugin) {
        this.hiddenSignup = true;
        if (localStorage.user) {
          this.$router.push("/dashboard");
        }
      } else {
        localStorage.clear();
      }
    }, 1000);
  }
};
</script>
