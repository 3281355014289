<template>
  <div class="expande-horizontal centraliza wrap pt-12">
    <v-flex xs12>
      <div class="expande-horizontal centraliza">
        <img style="filter: invert(1);" height="40" src="logo.jpeg" />
      </div>
    </v-flex>
    <div class="privacy-policy">
      <h1>Política de Privacidade</h1>
  
      <section>
        <h2>1. Objetivo desta Política</h2>
        <p>
          A REVOLUTION SOLUÇÕES TECNOLÓGICAS PARA O VAREJO LTDA., doravante denominada REVOLUTION, empresa com sede em São Paulo/SP, inscrita no CNPJ sob o número 27.659.353/0001-54, é controladora dos dados pessoais dos usuários cadastrados na plataforma SCN. Emprega os melhores esforços para fornecer medidas de proteção adequadas em todas as suas operações e para implementar as políticas e os procedimentos mais consistentes, efetivos e rigorosos. Por reconhecer a importância da sua privacidade, desenvolvemos esta Política para informá-lo a respeito das condições sob as quais Tratamos e Protegemos seus Dados Pessoais. Esta Política também descreve seus direitos e como você pode entrar em contato conosco para exercê-los ou nos fazer quaisquer perguntas que você possa ter sobre a proteção de seus Dados Pessoais.
        </p>
        <p>
          Os termos dessa Política se aplicam aos Usuários e/ou Visitantes do website <a href="https://scnrevolution.com/" target="_blank">https://scnrevolution.com/</a> ou <a href="https://app.scnrevolution.com/" target="_blank">https://app.scnrevolution.com/</a>, também conhecidos como CANAIS DIGITAIS.
        </p>
      </section>
  
      <section>
        <h2>2. Origem dos Dados Pessoais</h2>
        <p>A REVOLUTION coleta e trata os seus Dados Pessoais que poderão ser recebidos:</p>
        <ul>
          <li>Ao fornecer informações, ao utilizar nossos sites e/ou App;</li>
          <li>Pela empresa que contrata os serviços da REVOLUTION em seu benefício;</li>
          <li>Por coleta automática ao navegar nos sites e/ou App;</li>
          <li>Por comunicação com outros usuários;</li>
          <li>Por coleta de informações por meio de outras fontes confiáveis.</li>
        </ul>
        <p>As informações coletadas pela REVOLUTION incluem:</p>
        <ul>
          <li><strong>Sendo pessoa física:</strong> nome completo, e-mail, senha, número de telefone, endereço, CPF, profissão, foto, data de nascimento e RG.</li>
          <li><strong>Sendo pessoa jurídica:</strong> nome completo do representante, e-mail, senha, número de telefone, endereço, CPF, profissão, foto, data de nascimento e RG, CNPJ e endereço comercial.</li>
        </ul>
      </section>
  
      <section>
        <h2>3. Finalidades para as Quais Utilizamos Dados Pessoais</h2>
        <p>
          Os Dados Pessoais que a REVOLUTION coleta são utilizados para fornecer os seus serviços, garantir que funcionem conforme o esperado, para aprimorá-los e, também, para ajudar a melhorar a confiabilidade e segurança de todos os nossos serviços.
        </p>
        <p>Especificamente, a REVOLUTION poderá utilizar seus Dados Pessoais para as seguintes finalidades:</p>
        <ul>
          <li>Administrar, prestar os serviços e cumprir as obrigações decorrentes dos serviços disponibilizados pela REVOLUTION;</li>
          <li>Informar sobre novidades, serviços, funcionalidades, conteúdos, benefícios, promoções, notícias e demais eventos relevantes e de relacionamento com os usuários;</li>
          <li>Criação de plano de ação de melhoria de nossos serviços/produtos;</li>
          <li>Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;</li>
          <li>Qualquer outra finalidade que será informada a você, se aplicável, quando coletamos seus dados.</li>
        </ul>
        <p>A REVOLUTION não vende e/ou comercializa informações que possam identificá-lo.</p>
      </section>
  
      <section>
        <h2>4. Base Legal para o Tratamento de Dados Pessoais</h2>
        <p>
          A REVOLUTION realiza o Tratamento dos Dados Pessoais como parte do desempenho e gestão de nosso relacionamento contratual com você e/ou com a empresa contratante dos nossos serviços, em nosso legítimo interesse ou de terceiros, desde que os requisitos legais para tanto sejam atendidos, em conformidade com certas obrigações legais ou regulatórias, ou quando for necessário para o exercício regular de direitos em processo judicial, administrativo ou arbitral. Seus Dados Pessoais também podem ser tratados com base em seu prévio consentimento, caso, sob certas circunstâncias, seu consentimento seja solicitado.
        </p>
      </section>
  
      <section>
        <h2>5. Compartilhamento de Dados Pessoais</h2>
        <p>
          A segurança e a confidencialidade de seus Dados Pessoais são de grande importância para nós. É por isso que restringimos o acesso a seus Dados Pessoais somente aos nossos profissionais devidamente autorizados que precisem ter conhecimento sobre tais dados para processar seus pedidos ou fornecer o(s) serviço(s) solicitado(s).
        </p>
        <p>
          Não divulgaremos seus Dados Pessoais a terceiros não autorizados. No entanto, podemos compartilhar seus Dados Pessoais com entidades dentro do grupo (cliente) e com prestadores de serviços autorizados, com os quais trabalhamos (por exemplo: Call Center; agentes de relacionamento; serviços de BackOffice; consultores nas áreas de tecnologia e informática; etc.), a quem podemos recorrer com o propósito de prestar nossos serviços. Nesses casos, garantimos que os seus Dados Pessoais sejam tratados apenas para propósitos legítimos, específicos, explícitos e informados a você, sem possibilidade de Tratamento posterior de forma incompatível com a finalidade informada.
        </p>
        <p>
          Além disso, podemos compartilhar Dados Pessoais a seu respeito, quando acreditamos, de boa-fé, que temos a obrigação de:
        </p>
        <ul>
          <li>Cumprir uma lei, regulamento ou ordem judicial;</li>
          <li>Responder solicitações de informação feitas por órgão regulador, autoridade policial e demais autoridades públicas, inclusive em casos relativos à segurança nacional;</li>
          <li>Fazer cumprir e/ou proteger propriedade ou direitos da REVOLUTION, inclusive para investigar fraudes e ajudar a prevenir ameaças de segurança, inclusive da informação, ou outras atividades criminosas ou maliciosas;</li>
          <li>Proteger os interesses legítimos de terceiros, incluindo a segurança pessoal dos funcionários da REVOLUTION.</li>
        </ul>
      </section>
  
      <section>
        <h2>6. Armazenamento de Seus Dados Pessoais</h2>
        <p>
          Armazenamos seus Dados Pessoais em nossas bases, em ambiente seguro, garantindo o sigilo e a sua confidencialidade, somente pelo tempo necessário para cumprir as finalidades para as quais foram coletados, observados os eventuais prazos legais e a necessidade de defesa da REVOLUTION em possíveis processos. No entanto, eventualmente, podemos ter que manter os seus Dados Pessoais por um período superior, por motivo de leis ou regulamentos aplicáveis. Findo o prazo e a necessidade legal, os seus Dados Pessoais serão excluídos com uso de métodos de descarte seguro ou utilizados de forma anonimizada para fins estatísticos.
        </p>
      </section>
  
      <section>
        <h2>7. Dados Pessoais Sensíveis e de Crianças e Adolescentes</h2>
        <p>
          Caso seja estritamente necessário coletar tais dados para atingir a finalidade para a qual o tratamento é realizado, o faremos de acordo com as exigências legais locais para a proteção de Dados Pessoais e, em particular, com seu prévio consentimento específico e destacado, para finalidades específicas sob as condições descritas nesta Política.
        </p>
      </section>
  
      <section>
        <h2>8. Transferência Internacional de Dados Pessoais</h2>
        <p>
          Como a REVOLUTION possui área de atuação aderente à área de atuação de seus CLIENTES, eventualmente, seus Dados Pessoais podem ser transferidos para destinatários localizados fora do Brasil e autorizados a realizar serviços em nosso nome. Na hipótese de transferência internacional com destinatários localizados em outros países, adotaremos as medidas necessárias para assegurar a existência de garantias adequadas para a proteção dos seus Dados Pessoais de acordo com a LGPD e em observância às proteções descritas na presente Política.
        </p>
      </section>
  
      <section>
        <h2>9. Exercício de Seus Direitos Decorrentes da LGPD</h2>
        <p>
          A REVOLUTION respeita os direitos que você possui na qualidade de titular de Dados Pessoais e possibilita seu exercício em conformidade com o artigo 18 da Lei n. 13.709/2018.
        </p>
        <p>
          Para exercer qualquer um dos seus direitos acima mencionados, veja a seção: 13. FALE CONOSCO SOBRE SEUS DIREITOS DECORRENTES DA LGPD.
        </p>
        <p>
          Para garantir a sua privacidade, e como medida de segurança e prevenção à fraude, podemos solicitar a comprovação da sua identidade antes de efetivarmos os seus direitos.
        </p>
      </section>
  
      <section>
        <h2>10. Alterações desta Política</h2>
        <p>
          Esta Política pode ser alterada a qualquer momento, visando o atendimento a exigências legais, regulamentares e melhorias em nossos processos. Recomendamos que você a consulte periodicamente para verificar possíveis alterações.
        </p>
      </section>
  
      <section>
        <h2>11. Segurança dos Dados Pessoais</h2>
        <p>
          Empregamos técnicas de segurança para proteger seus Dados Pessoais contra acesso não autorizado, alteração, divulgação ou destruição. Essas técnicas incluem criptografia, firewalls e controles de acesso.
        </p>
      </section>
  
      <section>
        <h2>12. Links para Outros Sites</h2>
        <p>
          Nossos canais digitais podem conter links para outros sites que não são operados por nós. Não nos responsabilizamos pelo conteúdo ou práticas de privacidade desses sites. Recomendamos que você leia as políticas de privacidade desses sites.
        </p>
      </section>
  
      <section>
        <h2>13. Fale Conosco Sobre Seus Direitos Decorrentes da LGPD</h2>
        <p>
          Para mais informações ou para exercer seus direitos, você pode entrar em contato conosco pelo e-mail: <a href="mailto:contato@scnrevolution.com">contato@scnrevolution.com</a>.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Policy'
}
</script>

<style scoped>
.privacy-policy {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
  padding: 0;
  color: #333;
}
h1, h2, h3 {
  color: #004d99;
}
ul {
  margin: 10px 0;
  padding: 0 20px;
}
li {
  margin-bottom: 10px;
}
section {
  margin-bottom: 20px;
}
a {
  color: #004d99;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
</style>
