<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <v-tabs
          show-arrows
          grow
          dark
          background-color="grey darken-3"
          color="yellow darken-4"
          v-model="tab"
        >
          <v-tab>Diretos - {{ getUnilevels.level_1.length }}</v-tab>
          <v-tab>Nivel 2 - {{ getUnilevels.level_2.length }}</v-tab>
          <v-tab>Nivel 3 - {{ getUnilevels.level_3.length }}</v-tab>
          <v-tab>Nivel 4 - {{ getUnilevels.level_4.length }}</v-tab>
          <!-- <v-tab>Nivel 5 - {{ getUnilevels.level_5.length }}</v-tab>
          <v-tab>Nivel 6 - {{ getUnilevels.level_6.length }}</v-tab>
          <v-tab>Nivel 7 - {{ getUnilevels.level_7.length }}</v-tab>
          <v-tab>Nivel 8 - {{ getUnilevels.level_8.length }}</v-tab> -->
          <!-- <v-tab>Nivel 9 - {{ getUnilevels.level_9.length }}</v-tab>
          <v-tab>Nivel 10 - {{ getUnilevels.level_10.length }}</v-tab> -->
        </v-tabs>
      </v-flex>

      <v-flex v-if="tab === 0" xs12 class="pa-1">
        <v-simple-table
          v-show="getUnilevels.level_1.length > 0"
          dense
          style="width: 100%;"
          v-if="$vuetify.breakpoint.lgAndUp"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 20px;">
                  <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                </th>
                <th class="fonte">NOME</th>
                <!-- <th class="fonte">TELEFONE</th> -->
                <th class="fonte">LOGIN</th>
                <th class="fonte">ATIVO</th>
                <th class="fonte">DATA DE INGRESSO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getUnilevels.level_1" :key="item._id">
                <td style="width: 20px;">
                  <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.indicated_name }}
                </td>
                <!-- <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td> -->
                <td class="font-weight-bold fonte">
                  {{ item.indicated_login }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ item.active ? "sim" : "não" }}
                </td>
                <td class="font-weight-bold fonte">
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-list class="pa-0" dark v-else>
          <template v-for="(item, index) in getUnilevels.level_1">
            <v-list-item :key="item._id">
              <v-avatar>
                <v-icon>mdi-account-circle</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.indicated_name }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle> {{ item.indicated_phone }} </v-list-item-subtitle> -->
                <v-list-item-subtitle class="fonte orange--text">
                  Desde
                  {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <Empty v-if="getUnilevels.level_1.length === 0"></Empty>
      </v-flex>

      <div v-if="tab === 1" class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table dense v-if="getUnilevels.level_2.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_2" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-if="getUnilevels.level_2.length === 0"></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 2" class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table dense v-if="getUnilevels.level_3.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_3" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-if="getUnilevels.level_3.length === 0"></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 3" dense class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table v-if="getUnilevels.level_4.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_4" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 4" dense class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table v-if="getUnilevels.level_5.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_5" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 5" dense class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table v-if="getUnilevels.level_6.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_6" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 6" dense class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table v-if="getUnilevels.level_7.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_7" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>

      <div v-if="tab === 7" dense class="expande-horizontal">
        <v-flex xs12 class="pa-3">
          <v-simple-table v-if="getUnilevels.level_8.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </th>
                  <th class="fonte">NOME</th>
                  <th class="fonte">TELEFONE</th>
                  <th class="fonte">LOGIN</th>
                  <th class="fonte">ATIVO</th>
                  <th class="fonte">DATA DE INGRESSO</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getUnilevels.level_8" :key="item._id">
                  <td style="width: 20px;">
                    <v-icon :color="$theme.primary">mdi-network-outline</v-icon>
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_name }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_phone }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.indicated_login }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{ item.active ? "sim" : "não" }}
                  </td>
                  <td class="font-weight-bold fonte">
                    {{
                      $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm")
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <Empty v-else></Empty>
        </v-flex>
      </div>
      <!-- <div v-if="tab === 8" dense class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table v-if="getUnilevels.level_9.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">TELEFONE</th>
                                <th class="fonte">LOGIN</th>
                                <th class="fonte">ATIVO</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_9" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.indicated_name }}</td>
                                <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td>
                                <td class="font-weight-bold fonte">{{ item.indicated_login }}</td>
                                <td class="font-weight-bold fonte">{{ item.active ? 'sim' : 'não' }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>
            <div v-if="tab === 9" dense class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table v-if="getUnilevels.level_10.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">TELEFONE</th>
                                <th class="fonte">LOGIN</th>
                                <th class="fonte">ATIVO</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_10" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.indicated_name }}</td>
                                <td class="font-weight-bold fonte">{{ item.indicated_phone }}</td>
                                <td class="font-weight-bold fonte">{{ item.indicated_login }}</td>
                                <td class="font-weight-bold fonte">{{ item.active ? 'sim' : 'não' }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div> -->
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Template from "../shared/components/Template.vue";
export default {
  components: { Template },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    ...mapGetters(["getUnilevels"])
  },
  methods: {
    ...mapActions(["listUnilevels"])
  },
  created() {
    this.listUnilevels();
  }
};
</script>
