<template>
  <div class="expande-horizontal column pt-0">
    <v-flex xs12>
      <div class="windows-style expande-horizontal fonte column">
        <div class="expande-horizontal pa-2 column">
          <v-list-item class="pa-0 ma-0">
            <v-list-item-content>
              <span class="fonte-bold" style="font-size: 23pt;">
                {{
                  !get_establishment._id
                    ? "Bem vindo(a), cadastre sua empresa abaixo."
                    : get_establishment.nome
                }}
              </span>
              <span
                v-if="!get_establishment._id"
                class="fonte grey--text"
                style="font-size: 12pt"
              >
                cadastrar sua empresa
              </span>
              <span v-else class="fonte grey--text" style="font-size: 12pt">
                veja sua empresa abaixo
              </span>
            </v-list-item-content>
            <v-list-item-action>
              <div v-if="get_establishment._id" class="expande-horizontal">
                <v-chip class="ma-2">
                  <v-icon size="21" class="mr-2" :color="$theme.primary"
                    >mdi-storefront</v-icon
                  >
                  <span class="fonte-bold" style="font-size: 18pt;"
                    >ID: SCN00{{ get_establishment.id_establishment }}</span
                  >
                </v-chip>
              </div>
            </v-list-item-action>
          </v-list-item>
        </div>
      </div>
    </v-flex>
    <div
      style="min-height: 75vh; max-height: 75vh; overflow: auto;"
      class="windows-style-content pt-3 expande-horizontal wrap"
    >
      <v-tabs
        v-model="tabs"
        :background-color="$theme.primary"
        dark
        v-if="false"
        class="fonte"
      >
        <v-tab>Info do estabelecimento</v-tab>
        <v-tab v-show="false" @click="listClientSales"
          >Compras do establishment</v-tab
        >
      </v-tabs>
      <v-flex v-show="tabs == 0" xs12>
        <v-form ref="form">
          <div class="expande-horizontal wrap">
            <v-flex xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-file-sign</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold"
                    >Dados da empresa</span
                  >
                  <span class="fonte grey--text"
                    >Informe os dados da empresa</span
                  >
                </div>
              </div>
            </v-flex>
            <v-flex class="px-2" xs12 md6>
              <v-text-field
                v-model="get_establishment.nome"
                filled
                label="Razão Social"
                dense
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                placeholder="ex: estabelecimentos S.A."
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md6>
              <v-text-field
                v-model="get_establishment.nome_fantasia"
                filled
                label="Nome fantasia"
                dense
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                placeholder="ex: estabelecimentos Ltda."
              ></v-text-field>
            </v-flex>
          </div>

          <div class="expande-horizontal wrap">
            <v-flex class="px-2" xs12 md6>
              <v-text-field
                v-model="get_establishment.email"
                dense
                filled
                :color="$theme.primary"
                :rules="[v => !!v || 'Preencha este campo']"
                label="Email"
                placeholder="ex: meuemail@gmail.com"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md6>
              <v-autocomplete
                v-model="get_establishment.obs"
                dense
                filled
                :items="commercialEstablishmentCategories"
                :color="$theme.primary"
                label="Categoria"
                placeholder="ex: Restaurante"
                :rules="[v => !!v || 'Preencha este campo']"
              ></v-autocomplete>
            </v-flex>
          </div>

          <div class="expande-horizontal wrap">
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.cpf"
                label="CNPJ"
                :disabled="get_establishment._id ? true : false"
                v-mask="['##.###.###/####-##']"
                :rules="[v => !!v || 'Preencha este campo']"
                filled
                :color="$theme.primary"
                placeholder="ex: 00.000.000/0000-00"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.telefone"
                label="Contato"
                v-mask="['(##) # #### ####']"
                :rules="[v => !!v || 'Preencha este campo']"
                dense
                filled
                :color="$theme.primary"
                placeholder="ex: (96) 9 8419-6827"
              ></v-text-field>
            </v-flex>
            <v-flex class="px-2" xs12 md4>
              <v-text-field
                v-model="get_establishment.data_nascimento"
                label="Data de Abertura"
                filled
                v-mask="['##/##/####']"
                dense
                :rules="[v => !!v || 'Preencha este campo']"
                :color="$theme.primary"
                placeholder="ex: 12/12/1984"
              ></v-text-field>
            </v-flex>
            <!-- 
              - Nome da mãe
              - Endereço
            -->
            <v-flex class="px-2" xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12 class="px-3">
                  <div class="expande-horizontal centraliza">
                    <div class="mr-3">
                      <v-icon color="blue">mdi-file-sign</v-icon>
                    </div>
                    <div class="expande-horizontal column title-item">
                      <span style="font-size: 16pt;" class="fonte-bold"
                        >Endereço</span
                      >
                      <span class="fonte grey--text"
                        >Informe o endereço da empresa</span
                      >
                    </div>
                  </div>
                </v-flex>
                <v-flex class="px-1" xs12 md3>
                  <v-text-field
                    v-model="get_establishment.address.cep"
                    label="CEP"
                    filled
                    :rules="[v => !!v || 'Preencha este campo']"
                    @blur="getCepForAddress"
                    dense
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md9>
                  <v-text-field
                    v-model="get_establishment.address.endereco"
                    label="Endereço"
                    filled
                    dense
                    :rules="[v => !!v || 'Preencha este campo']"
                    :color="$theme.primary"
                    placeholder="ex: Informe o endereço aqui"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md9>
                  <v-text-field
                    v-model="get_establishment.address.numero"
                    label="Número"
                    filled
                    ref="AddressNumber"
                    dense
                    :rules="[v => !!v || 'Preencha este campo']"
                    :color="$theme.primary"
                    placeholder="ex: Número"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.bairro"
                    label="Bairro"
                    :rules="[v => !!v || 'Preencha este campo']"
                    filled
                    dense
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.cidade"
                    label="Município"
                    filled
                    dense
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
                <v-flex class="px-1" xs12 md4>
                  <v-text-field
                    v-model="get_establishment.address.estado"
                    label="Estado"
                    filled
                    :rules="[v => !!v || 'Preencha este campo']"
                    dense
                    :color="$theme.primary"
                  ></v-text-field>
                </v-flex>
              </div>
            </v-flex>
          </div>
          <!-- <div class="expande-horizontal">
            <v-flex class="px-2" xs12>
              <v-textarea
                v-model="get_establishment.obs"
                label="Anotações sobre o establishment"
                filled
                dense
                :color="$theme.primary"
              ></v-textarea>
            </v-flex>
          </div> -->
          <div class="expande-horizontal wrap">
            <v-flex xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-file-sign</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold"
                    >Documentos</span
                  >
                  <span class="fonte grey--text"
                    >Carrega os documentos da empresa abaixo</span
                  >
                </div>
              </div>
            </v-flex>
            <document-files
              :items="[
                {
                  name: 'Ato constitutivo ou Contrato Social',
                  required: true
                },
                {
                  name: 'Cartão CNPJ',
                  required: true
                },
                {
                  name: 'Comprovante de endereço da Empresa',
                  required: true
                }
              ]"
              v-model="get_establishment.documents"
              :basePath="`documents/establishment`"
              @input="valida_form"
            ></document-files>
            <v-flex class="px-2" xs12>
              <div class="expande-horizontal wrap">
                <v-flex xs12 md4>
                  <v-list color="transparent">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Termos de uso
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <a
                            target="_blank"
                            href="https://adm.scnrevolution.com.br/terms_policy/termo.pdf"
                            >Veja o termo de uso</a
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch v-model="get_establishment.termos"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex xs12 md4>
                  <v-list color="transparent">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Política de Privacidade
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <a
                            target="_blank"
                            href="https://adm.scnrevolution.com.br/terms_policy/aviso.pdf"
                            >Veja a política de privacidade</a
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch v-model="get_establishment.politica"></v-switch>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
            </v-flex>
            <v-flex v-if="false" xs12 class="px-3">
              <div class="expande-horizontal centraliza">
                <div class="mr-3">
                  <v-icon color="blue">mdi-card-account-details-outline</v-icon>
                </div>
                <div class="expande-horizontal column title-item">
                  <span style="font-size: 16pt;" class="fonte-bold"
                    >Sócios</span
                  >
                  <span class="fonte grey--text"
                    >Carrega os sócios da empresa abaixo</span
                  >
                </div>
              </div>
            </v-flex>
            <div v-if="false" class="expande-horizontal wrap column">
              <span
                class="ma-3"
                v-if="
                  !get_establishment.partners ||
                    get_establishment.partners.length == 0
                "
              >
                Não há sócios cadastrados
              </span>
              <div class="pa-3 expande-horizontal" v-else>
                <v-flex class="pa-6" xs12>
                  <v-list color="transparent">
                    <v-list-item
                      style="margin-bottom: 6px; border-radius: 6px; background-color: #fff;"
                      v-for="(partner, i) in get_establishment.partners"
                      :key="`partner-${i}`"
                    >
                      <v-avatar>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ partner.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ partner.cpf }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-flex>
              </div>
              <div class="expande-horizontal pa-2">
                <v-btn color="green" dark @click="initPartnerForm"
                  >Cadastrar sócio</v-btn
                >
              </div>
            </div>
            <v-dialog v-model="new_partner_dialog" width="380">
              <v-card class="pa-2">
                <v-flex xs12>
                  <div class="expande-horizontal wrap">
                    <v-flex xs12>
                      <v-list color="transparent">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Cadastro de Sócio
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Preencha as informações abaixo
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="new_partner_dialog = false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12>
                      <div class="expande-horizontal column">
                        <v-text-field
                          label="Nome do sócio"
                          v-model="partner.name"
                          filled
                          dense
                          :color="$theme.primary"
                          placeholder="ex: João da Silva"
                        >
                        </v-text-field>

                        <v-text-field
                          label="CPF"
                          v-model="partner.cpf"
                          filled
                          dense
                          :color="$theme.primary"
                          v-mask="['###.###.###-##']"
                          placeholder="ex: ###.###.###-##"
                        ></v-text-field>
                        <document-files
                          key="partners"
                          id="partners"
                          v-model="partner.documents"
                          :basePath="`documents/establishment/partners`"
                          :items="[
                            {
                              name: 'Habilitação ou RG ou CPF',
                              required: true
                            },
                            {
                              name: 'Comprovante de endereço do sócio',
                              required: true
                            }
                          ]"
                          @input="verifyDocumentFiles($event)"
                        ></document-files>
                        <v-btn
                          color="green"
                          dark
                          @click="addPartner"
                          v-if="ready_to_add"
                          class="ma-2"
                          >Finalizar</v-btn
                        >
                      </div>
                    </v-flex>
                  </div>
                </v-flex>
              </v-card>
            </v-dialog>
          </div>
        </v-form>
      </v-flex>
      <!-- Compras do establishment -->
      <v-flex v-show="tabs == 1" xs12>
        <div class="expande-horizontal wrap">
          <h2 class="fonte-bold ml-3">
            O establishment realizou {{ sales.length }} compras.
          </h2>
          <div
            style="min-height: 67vh; max-height: 67vh; overflow: auto;"
            class="expande-horizontal px-3"
            v-if="sales.length > 0"
          >
            <v-flex xs12 md3 class="mr-3" v-for="sale in sales" :key="sale._id">
              <SaleItem :kanban="sale" />
            </v-flex>
          </div>

          <div class="expande-horizontal centraliza" v-else>
            <span class="my-12 fonte"
              >Este establishment ainda não realizou nenhuma compra</span
            >
          </div>
        </div>
      </v-flex>
    </div>

    <div class="expande-horizontal py-1 windows-style-bottom">
      <v-btn
        v-if="get_establishment._id"
        x-large
        text
        @click="$router.push('/dashboard')"
        color="black"
      >
        <span class="fonte-bold px-12"> Voltar </span>
      </v-btn>
      <v-btn
        :disabled="!get_establishment.termos || !get_establishment.politica"
        x-large
        dark
        @click="valida_form(true)"
        color="black"
      >
        <span class="fonte-bold px-12"> Salvar </span>
      </v-btn>
      <!-- <v-btn
        v-if="get_establishment._id"
        color="red"
        class="ml-3"
        @click="excluir_establishment_dialog(get_establishment)"
        text
        >Excluir</v-btn
      > -->
    </div>
  </div>
</template>

<script>
import SaleItem from "./SaleItem.vue";
import { mapActions, mapGetters } from "vuex";
import DocumentFiles from "@/apps/2#auth/components/DocumentFiles.vue";
import axios from "axios";

export default {
  components: {
    SaleItem,
    DocumentFiles
  },
  computed: {
    ...mapGetters(["get_establishment", "getLoggedUser"])
  },
  data() {
    return {
      tabs: 0,
      sales: [],
      partner: {
        documents: []
      },
      new_partner_dialog: false,
      ready_to_add: false,
      commercialEstablishmentCategories: [
        "Supermercados",
        "Padarias",
        "Restaurantes",
        "Cafés",
        "Bares",
        "Lanchonetes",
        "Pizzarias",
        "Churrascarias",
        "Docerias",
        "Sorveterias",
        "Açougues",
        "Peixarias",
        "Mercearias",
        "Frutarias",
        "Lojas de Conveniência",
        "Lojas de Roupas",
        "Lojas de Calçados",
        "Lojas de Acessórios",
        "Lojas de Bijuterias",
        "Joalherias",
        "Perfumarias",
        "Farmácias",
        "Drogarias",
        "Óticas",
        "Livrarias",
        "Papelerias",
        "Lojas de Eletrônicos",
        "Lojas de Informática",
        "Lojas de Eletrodomésticos",
        "Lojas de Móveis",
        "Lojas de Decoração",
        "Lojas de Material de Construção",
        "Lojas de Ferragens",
        "Lojas de Tintas",
        "Lojas de Automóveis",
        "Concessionárias",
        "Oficinas Mecânicas",
        "Lojas de Pneus",
        "Postos de Combustível",
        "Lavanderias",
        "Tinturarias",
        "Salões de Beleza",
        "Barbearias",
        "Estúdios de Tatuagem",
        "Estúdios de Piercing",
        "Academias de Ginástica",
        "Estúdios de Yoga",
        "Estúdios de Pilates",
        "Centros de Estética",
        "Clínicas Médicas",
        "Clínicas Odontológicas",
        "Laboratórios de Análises",
        "Hospitais",
        "Pet Shops",
        "Clinicas Veterinárias",
        "Hotéis",
        "Pousadas",
        "Hostels",
        "Motéis",
        "Agências de Turismo",
        "Agências de Viagem",
        "Imobiliárias",
        "Corretores de Seguros",
        "Bancos",
        "Caixas Econômicas",
        "Cooperativas de Crédito",
        "Lotéricas",
        "Agências de Correios",
        "Gráficas",
        "Editoras",
        "Lojas de Brinquedos",
        "Lojas de Artigos Esportivos",
        "Lojas de Artigos para Festa",
        "Lojas de Animais",
        "Lojas de Produtos Naturais",
        "Lojas de Artesanato",
        "Galerias de Arte",
        "Antiquários",
        "Mercados de Pulgas",
        "Feiras Livres",
        "Feiras de Artesanato",
        "Mercados Municipais",
        "Shopping Centers",
        "Galerias Comerciais",
        "Hipermercados",
        "Atacadistas",
        "Distribuidoras",
        "Empórios",
        "Casas de Massagem",
        "Casas Noturnas",
        "Casas de Show",
        "Teatros",
        "Cinemas",
        "Museus",
        "Parques de Diversões",
        "Parques Aquáticos",
        "Clubes Sociais",
        "Clubes Esportivos",
        "Associações Recreativas",
        "Centros Culturais",
        "Escolas de Idiomas",
        "Escolas de Música",
        "Escolas de Dança",
        "Escolas de Informática",
        "Cursos Preparatórios",
        "Faculdades",
        "Universidades",
        "Escolas Técnicas",
        "Escolas Profissionalizantes",
        "Jardins de Infância",
        "Creches",
        "Berçários",
        "Buffets Infantis",
        "Buffets para Eventos",
        "Locadoras de Veículos",
        "Locadoras de Equipamentos",
        "Produtoras de Vídeo",
        "Produtoras de Áudio",
        "Estúdios de Fotografia",
        "Empresas de Publicidade",
        "Agências de Marketing",
        "Consultorias",
        "Empresas de TI",
        "Empresas de Segurança",
        "Empresas de Limpeza",
        "Empresas de Conservação",
        "Transportadoras",
        "Correios",
        "Empresas de Logística",
        "Empresas de Mudança",
        "Serralherias",
        "Marcenarias",
        "Estofarias",
        "Vidraceiras",
        "Funerárias",
        "Cemitérios",
        "Creches para Animais",
        "Daycare para Animais",
        "Hotéis para Animais",
        "Centro de Treinamento de Animais",
        "Academias de Treinamento Funcional",
        "Espaços de Coworking",
        "Bibliotecas",
        "Videolocadoras",
        "Lavagens de Carros",
        "Estacionamentos",
        "Parquímetros",
        "Condomínios Comerciais",
        "Condomínios Residenciais",
        "Sindicatos",
        "Associações Profissionais",
        "Clubes de Serviço",
        "ONGs",
        "Instituições de Caridade",
        "Organizações Religiosas",
        "Templos",
        "Igrejas",
        "Mesquitas",
        "Sinagogas",
        "Centros Espíritas",
        "Terrenos de Umbanda",
        "Candomblé",
        "Outras Religiões",
        "Escritórios de Advocacia",
        "Contabilidades",
        "Consultórios de Psicologia",
        "Consultórios de Psiquiatria",
        "Consultórios de Fisioterapia",
        "Consultórios de Nutrição",
        "Consultórios de Terapia Ocupacional",
        "Consultórios de Acupuntura",
        "Consultórios de Quiropraxia",
        "Consultórios de Podologia",
        "Consultórios de Homeopatia",
        "Consultórios de Medicina Alternativa",
        "Outros Consultórios",
        "Agências de Emprego",
        "Empresas de Recrutamento",
        "Empresas de Seleção",
        "Empresas de Treinamento e Desenvolvimento",
        "Empresas de Recursos Humanos",
        "Empresas de Eventos",
        "Empresas de Festas",
        "Casas de Festas",
        "Empresas de Buffet",
        "Organizadores de Eventos",
        "Decoradores de Eventos",
        "Empresas de Iluminação",
        "Empresas de Som",
        "Empresas de Audiovisual",
        "Empresas de Catering",
        "Empresas de Locação de Equipamentos para Eventos",
        "Empresas de Transporte de Passageiros",
        "Empresas de Turismo",
        "Agências de Modelos",
        "Agências de Atores",
        "Agências de Figurantes",
        "Agências de Fotógrafos",
        "Agências de Publicidade",
        "Agências de Marketing Digital",
        "Agências de Design",
        "Agências de Comunicação",
        "Agências de Relações Públicas",
        "Agências de Branding",
        "Empresas de Consultoria",
        "Empresas de Auditoria",
        "Empresas de Compliance",
        "Empresas de Gestão",
        "Empresas de Projetos",
        "Empresas de Engenharia",
        "Empresas de Arquitetura",
        "Empresas de Paisagismo",
        "Empresas de Urbanismo",
        "Empresas de Construção Civil",
        "Empresas de Reformas",
        "Empresas de Manutenção Predial",
        "Empresas de Instalações Elétricas",
        "Empresas de Instalações Hidráulicas",
        "Empresas de Ar Condicionado",
        "Empresas de Ventilação",
        "Empresas de Refrigeração",
        "Empresas de Energia Solar",
        "Empresas de Energia Eólica",
        "Empresas de Energia Renovável",
        "Empresas de Tratamento de Água",
        "Empresas de Tratamento de Efluentes",
        "Empresas de Gestão de Resíduos",
        "Empresas de Reciclagem",
        "Empresas de Logística Reversa",
        "Empresas de Transporte de Cargas",
        "Empresas de Armazenagem",
        "Empresas de Distribuição",
        "Empresas de Gestão de Estoques",
        "Empresas de Frete",
        "Empresas de Courier",
        "Empresas de Mensageria",
        "Empresas de Motoboy",
        "Empresas de Transporte Expresso",
        "Empresas de Transporte de Documentos",
        "Empresas de Transporte de Mercadorias",
        "Empresas de Transporte de Valores",
        "Empresas de Segurança Patrimonial",
        "Empresas de Segurança Pessoal",
        "Empresas de Monitoramento",
        "Empresas de Rastreamento",
        "Empresas de Vigilância",
        "Empresas de Portaria",
        "Empresas de Zeladoria",
        "Empresas de Controle de Acesso",
        "Empresas de Tecnologia da Informação",
        "Empresas de Desenvolvimento de Software",
        "Empresas de Desenvolvimento Web",
        "Empresas de Desenvolvimento de Aplicativos",
        "Empresas de Desenvolvimento de Jogos",
        "Empresas de Desenvolvimento de Sistemas",
        "Empresas de Infraestrutura de TI",
        "Empresas de Suporte Técnico",
        "Empresas de Help Desk",
        "Empresas de Outsourcing de TI",
        "Empresas de Segurança da Informação",
        "Empresas de Cloud Computing",
        "Empresas de Data Center",
        "Empresas de Internet",
        "Empresas de Telecomunicações",
        "Empresas de Redes",
        "Empresas de Cabeamento Estruturado",
        "Empresas de Automação",
        "Empresas de Robótica",
        "Empresas de Inteligência Artificial",
        "Empresas de Big Data",
        "Empresas de Analytics",
        "Empresas de IoT",
        "Empresas de Blockchain",
        "Empresas de Fintech",
        "Empresas de Healthtech",
        "Empresas de Edtech",
        "Empresas de Agritech",
        "Empresas de Legaltech",
        "Empresas de Martech",
        "Empresas de Retailtech",
        "Empresas de Proptech",
        "Empresas de Construtech",
        "Empresas de HRtech",
        "Empresas de Logtech",
        "Empresas de Biotech",
        "Empresas de Cleantech",
        "Empresas de Greentech",
        "Empresas de Foodtech",
        "Empresas de Adtech",
        "Empresas de Medtech",
        "Empresas de Regtech",
        "Empresas de Govtech",
        "Empresas de Agrotech",
        "Empresas de Nanotech",
        "Empresas de Wearable Tech",
        "Empresas de Virtual Reality",
        "Empresas de Augmented Reality",
        "Empresas de Mixed Reality",
        "Empresas de 3D Printing",
        "Empresas de Drones",
        "Empresas de Cybersecurity",
        "Empresas de Insurtech",
        "Empresas de Paytech",
        "Empresas de Edtech",
        "Empresas de E-sports",
        "Empresas de Game Development",
        "Empresas de Game Design",
        "Empresas de Animation",
        "Empresas de VFX",
        "Empresas de Post-Production",
        "Empresas de Sound Design",
        "Empresas de Music Production",
        "Empresas de Event Production",
        "Empresas de Show Production",
        "Empresas de Festival Production",
        "Empresas de Conference Production",
        "Empresas de Exhibition Production",
        "Empresas de Fashion Design",
        "Empresas de Textile Design",
        "Empresas de Interior Design",
        "Empresas de Industrial Design",
        "Empresas de Graphic Design",
        "Empresas de Web Design",
        "Empresas de UX Design",
        "Empresas de UI Design",
        "Empresas de Service Design",
        "Empresas de Product Design",
        "Empresas de Digital Design",
        "Empresas de Packaging Design",
        "Empresas de Visual Design",
        "Empresas de Corporate Design",
        "Empresas de Branding",
        "Empresas de Identity Design",
        "Empresas de Environmental Design",
        "Empresas de Spatial Design",
        "Empresas de Exhibition Design",
        "Empresas de Set Design",
        "Empresas de Costume Design",
        "Empresas de Prop Design",
        "Empresas de Scenic Design",
        "Empresas de Lighting Design",
        "Empresas de Sound Design",
        "Empresas de Music Composition",
        "Empresas de Songwriting",
        "Empresas de Music Publishing",
        "Empresas de Music Licensing",
        "Empresas de Music Distribution",
        "Empresas de Record Labels",
        "Empresas de Music Management",
        "Empresas de Artist Management",
        "Empresas de Talent Management",
        "Empresas de Event Management",
        "Empresas de Tour Management",
        "Empresas de Concert Management",
        "Empresas de Festival Management",
        "Empresas de Venue Management",
        "Empresas de Sports Management",
        "Empresas de Entertainment Management",
        "Empresas de Media Management",
        "Empresas de Production Management",
        "Empresas de Project Management",
        "Empresas de Program Management",
        "Empresas de Portfolio Management",
        "Empresas de Process Management",
        "Empresas de Performance Management",
        "Empresas de Change Management",
        "Empresas de Risk Management",
        "Empresas de Quality Management",
        "Empresas de Resource Management",
        "Empresas de Stakeholder Management",
        "Empresas de Financial Management",
        "Empresas de Investment Management",
        "Empresas de Asset Management",
        "Empresas de Wealth Management",
        "Empresas de Fund Management",
        "Empresas de Portfolio Management",
        "Empresas de Risk Management",
        "Empresas de Compliance Management",
        "Empresas de Governance Management",
        "Empresas de Audit Management",
        "Empresas de Tax Management",
        "Empresas de Treasury Management",
        "Empresas de Accounting",
        "Empresas de Bookkeeping",
        "Empresas de Payroll",
        "Empresas de Financial Reporting",
        "Empresas de Budgeting",
        "Empresas de Forecasting",
        "Empresas de Financial Analysis",
        "Empresas de Financial Planning",
        "Empresas de Strategic Planning",
        "Empresas de Business Planning",
        "Empresas de Market Research",
        "Empresas de Competitive Intelligence",
        "Empresas de Business Intelligence",
        "Empresas de Data Analysis",
        "Empresas de Data Science",
        "Empresas de Machine Learning",
        "Empresas de Deep Learning",
        "Empresas de Natural Language Processing",
        "Empresas de Computer Vision",
        "Empresas de Robotics",
        "Empresas de Automation",
        "Empresas de Autonomous Systems",
        "Empresas de Cognitive Computing",
        "Empresas de Neuromorphic Computing",
        "Empresas de Quantum Computing",
        "Empresas de Photonic Computing",
        "Empresas de Edge Computing",
        "Empresas de Fog Computing",
        "Empresas de Grid Computing",
        "Empresas de Cloud Computing",
        "Empresas de Hybrid Cloud",
        "Empresas de Public Cloud",
        "Empresas de Private Cloud",
        "Empresas de Multi-Cloud",
        "Empresas de Cloud Security",
        "Empresas de Cloud Services",
        "Empresas de Cloud Solutions",
        "Empresas de Cloud Applications",
        "Empresas de Cloud Platforms",
        "Empresas de Cloud Infrastructure",
        "Empresas de Cloud Storage",
        "Empresas de Cloud Networking",
        "Empresas de Cloud Analytics",
        "Empresas de Cloud Integration",
        "Empresas de Cloud Automation",
        "Empresas de Cloud Orchestration",
        "Empresas de Cloud Management",
        "Empresas de Cloud Migration",
        "Empresas de Cloud Optimization",
        "Empresas de Cloud Governance",
        "Empresas de Cloud Compliance",
        "Empresas de Cloud Monitoring",
        "Empresas de Cloud Backup",
        "Empresas de Cloud Disaster Recovery",
        "Empresas de Cloud DevOps",
        "Empresas de Cloud FinOps",
        "Empresas de Cloud DevSecOps",
        "Empresas de Cloud AI",
        "Empresas de Cloud ML",
        "Empresas de Cloud IoT",
        "Empresas de Cloud Blockchain",
        "Empresas de Cloud Serverless",
        "Empresas de Cloud Containers",
        "Empresas de Cloud Kubernetes",
        "Empresas de Cloud Microservices",
        "Empresas de Cloud API",
        "Empresas de Cloud SaaS",
        "Empresas de Cloud PaaS",
        "Empresas de Cloud IaaS",
        "Empresas de Cloud FaaS",
        "Empresas de Cloud BaaS",
        "Empresas de Cloud DaaS",
        "Empresas de Cloud DRaaS",
        "Empresas de Cloud MaaS",
        "Empresas de Cloud NaaS",
        "Empresas de Cloud STaaS",
        "Empresas de Cloud CaaS",
        "Empresas de Cloud EaaS",
        "Empresas de Cloud XaaS",
        "Empresas de Cloud HPC",
        "Empresas de Cloud Big Data",
        "Empresas de Cloud Analytics",
        "Empresas de Cloud BI",
        "Empresas de Cloud Data Lake",
        "Empresas de Cloud Data Warehouse",
        "Empresas de Cloud Data Management",
        "Empresas de Cloud Data Governance",
        "Empresas de Cloud Data Privacy",
        "Empresas de Cloud Data Protection",
        "Empresas de Cloud Data Security",
        "Empresas de Cloud Data Quality",
        "Empresas de Cloud Data Integration",
        "Empresas de Cloud Data Migration",
        "Empresas de Cloud Data Backup",
        "Empresas de Cloud Data Recovery",
        "Empresas de Cloud Data Archiving",
        "Empresas de Cloud Data Retention",
        "Empresas de Cloud Data Compliance",
        "Empresas de Cloud Data Visualization",
        "Empresas de Cloud Data Analytics",
        "Empresas de Cloud Data Science",
        "Empresas de Cloud Data Engineering",
        "Empresas de Cloud Data Ops",
        "Empresas de Cloud Data DevOps",
        "Empresas de Cloud Data AI",
        "Empresas de Cloud Data ML",
        "Empresas de Cloud Data DL",
        "Empresas de Cloud Data NLP",
        "Empresas de Cloud Data CV",
        "Empresas de Cloud Data Robotics",
        "Empresas de Cloud Data Automation",
        "Empresas de Cloud Data IoT",
        "Empresas de Cloud Data Blockchain",
        "Empresas de Cloud Data Serverless",
        "Empresas de Cloud Data Containers",
        "Empresas de Cloud Data Kubernetes",
        "Empresas de Cloud Data Microservices",
        "Empresas de Cloud Data API",
        "Empresas de Cloud Data SaaS",
        "Empresas de Cloud Data PaaS",
        "Empresas de Cloud Data IaaS",
        "Empresas de Cloud Data FaaS",
        "Empresas de Cloud Data BaaS",
        "Empresas de Cloud Data DaaS",
        "Empresas de Cloud Data DRaaS",
        "Empresas de Cloud Data MaaS",
        "Empresas de Cloud Data NaaS",
        "Empresas de Cloud Data STaaS",
        "Empresas de Cloud Data CaaS",
        "Empresas de Cloud Data EaaS",
        "Empresas de Cloud Data XaaS",
        "Empresas de Cloud Data HPC"
      ]
    };
  },
  methods: {
    ...mapActions([
      "criar_establishment",
      "atualizar_establishment",
      "fecha_modal_view_establishment",
      "enableLoading",
      "disableLoading",
      "getCep",
      "createGlobalMessage"
    ]),
    getCepForAddress() {
      if (this.get_establishment.address.cep) {
        this.getCep(this.get_establishment.address.cep).then(res => {
          this.get_establishment.address.cep = res.cep;
          this.get_establishment.address.endereco = res.logradouro;
          this.get_establishment.address.complemento = res.complemento;
          this.get_establishment.address.bairro = res.bairro;
          this.get_establishment.address.cidade = res.localidade;
          this.get_establishment.address.uf = res.uf;
          this.get_establishment.address.estado = res.uf;
          this.$refs.AddressNumber.focus();
        });
      }
    },
    initPartnerForm() {
      this.new_partner_dialog = true;
      this.partner = {
        documents: []
      };
      this.ready_to_add = false;
    },
    verifyDocumentFiles(docs) {
      if (docs.length == 2) {
        this.ready_to_add = true;
      }
    },
    addPartner() {
      if (this.get_establishment.partners) {
        this.get_establishment.partners.push(this.partner);
      } else {
        this.get_establishment.partners = [this.partner];
      }

      this.new_partner_dialog = false;
      this.ready_to_add = false;
      this.valida_form();
    },
    listClientSales() {
      this.enableLoading();
      this.$run("establishments/list-client-sales", {
        _id: this.get_establishment._id
      }).then(res => {
        this.disableLoading();
        this.sales = res.data;
      });
    },
    async valida_form(login) {
      if (
        !this.get_establishment.documents ||
        this.get_establishment.documents.length < 3
      ) {
        this.createGlobalMessage({
          message: "Preencha os documentos da empresa",
          type: "error",
          timeout: 3000
        });
        return;
      }
      let address = this.get_establishment.address;
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: `${address.endereco} - ${address.numero} - ${address.estado}, ${address.bairro}, ${address.cidade} - ${address.cep}`,
            key: "AIzaSyAa5Gv7Du2ezz8fw6SHHY82vyDl7vIKO8U"
          }
        }
      );
      let geometry = response.data.results;
      this.get_establishment.address = {
        ...address,
        geometry: geometry.length ? geometry[0] : [],
        coordinates: geometry.length
          ? [
              geometry[0].geometry.location.lng,
              geometry[0].geometry.location.lat
            ]
          : []
      };
      if (this.$refs.form.validate()) {
        this.get_establishment._id
          ? this.atualizar_establishment(login)
          : this.criar_establishment(login);
      }
    },
    backToList() {
      this.$store.commit("set_establishment", {});
      this.$forceUpdate();
    }
  }
};
</script>

<style>
.title-item {
  padding: 6px;
}
</style>
