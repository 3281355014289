<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="$router.go(-1)"
            size="36"
            style="cursor: pointer;"
            color="#e5e5e5"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#333" size="28" style="padding-bottom: 8px;"
            >mdi-currency-usd</v-icon
          >
          <span
            style="font-size: 19pt; text-wrap: nowrap; white-space: nowrap; "
            class="fonte-bold"
          >
            {{ paymentMethodSelected }}
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="0">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 column expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Método de pagamento
              </span>
            </v-flex>
            <v-btn
              class="mb-3 fonte-bold white--text"
              style="font-size: 18pt; border-radius: 8px;"
              color="black"
              x-large
              @click="step = '1'"
            >
              SCN
            </v-btn>
            <!-- <v-btn
              class="mb-3 fonte-bold white--text"
              style="font-size: 18pt; border-radius: 8px;"
              color="black"
              x-large
              @click="preSdk('credit')"
            >
              Crédito
            </v-btn>
            <v-btn
              class="mb-3 fonte-bold white--text"
              style="font-size: 18pt; border-radius: 8px;"
              color="black"
              x-large
              @click="preSdk('debit')"
            >
              Débito
            </v-btn>
            <v-btn
              class="mb-3 fonte-bold white--text"
              style="font-size: 18pt; border-radius: 8px;"
              color="black"
              x-large
              @click="preSdk('pix')"
            >
              Pix
            </v-btn> -->
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <!-- <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                {{ cpf }}
              </span>
            </v-flex> -->
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                {{ cpf ? `CPF: ${cpfFormatted}` : "Informe o cpf do pagador" }}
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                placeholder="___.___.___-__"
                v-mask="['###########']"
                prefix="CPF:"
                type="number"
                large
                v-model="cpf"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <v-btn
                  @click="getDestUser"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Buscar</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '0'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="2">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Confirme o pagador
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                prepend-inner-icon="mdi-account-circle-outline"
                @keyup.enter="confirmDestUser"
                large
                readonly
                v-model="user.name"
                ref="userName"
                append-icon="mdi-check"
                @click:append="confirmDestUser"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal centraliza column">
                <v-btn
                  @click="confirmDestUser"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Confirmar</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '0'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="3">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex xs12>
              <v-list v-if="false" class="fonte" color="transparent">
                <v-list-item class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Pagador
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-check</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                {{
                  transfer_value
                    ? `${$helper.formataSaldo(transfer_value)} ${
                        payment_method == "credit"
                          ? `em ${installment_number}x `
                          : ""
                      } `
                    : "Valor a solicitar"
                }}
              </span>
            </v-flex>
            <v-flex v-if="payment_method == 'credit'" xs12>
              <div class="expande-horizontal wrap">
                <v-btn
                  :text="installment_number != installment.value"
                  color="#333"
                  @click="installment_number = installment.value"
                  x-large
                  :key="installment.name"
                  v-for="installment in [
                    {
                      name: 'à vista',
                      value: 1
                    },
                    {
                      name: '2x',
                      value: 2
                    },
                    {
                      name: '3x',
                      value: 3
                    },
                    {
                      name: '4x',
                      value: 4
                    },
                    {
                      name: '5x',
                      value: 5
                    },
                    {
                      name: '6x',
                      value: 6
                    },
                    {
                      name: '7x',
                      value: 7
                    },
                    {
                      name: '8x',
                      value: 8
                    },
                    {
                      name: '9x',
                      value: 9
                    },
                    {
                      name: '10x',
                      value: 10
                    },
                    {
                      name: '11x',
                      value: 11
                    },
                    {
                      name: '12x',
                      value: 12
                    }
                  ]"
                >
                  <span
                    class="fonte-bold"
                    :class="{
                      'white--text': installment_number == installment.value,
                      'black--text': installment_number != installment.value
                    }"
                    >{{ installment.name }}</span
                  >
                </v-btn>
              </div>
            </v-flex>
            <v-flex class="mt-3" xs12>
              <v-text-field
                filled
                color="black"
                rounded
                prefix="R$"
                v-mask="[
                  '#.##',
                  '##.##',
                  '###.##',
                  '####.##',
                  '#####.##',
                  '######.##'
                ]"
                placeholder="23,50"
                large
                type="number"
                v-model="transfer_value"
                :autofocus="!(payment_method == 'credit')"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal column centraliza">
                <v-btn
                  @click="createRequest"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Solicitar Pagamento</v-btn
                >
                <v-btn
                  class="mt-3"
                  @click="step = '0'"
                  :loading="loading"
                  small
                  color="grey"
                  dark
                  text
                  >Cancelar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="4">
        <v-flex xs12 class="px-3" md6>
          <div
            class="expande-horizontal centraliza wrap"
            style="min-height: 50vh;"
          >
            <!-- <v-otp-input length="6"></v-otp-input> -->
            <div class="expande-horizontal centraliza column">
              <span class="fonte-bold" style="font-size: 26pt;">{{
                $helper.formataSaldo(transfer_value.replace(",", ".") || 0)
              }}</span>
              <span class="fonte-bold grey--text" style="font-size: 19pt;"
                >Solicite a senha ao cliente</span
              >
            </div>

            <div v-if="!loading" class="expande-horizontal">
              <v-text-field
                v-for="(digit, index) in otpLength"
                :key="index"
                v-model="otp[index]"
                filled
                :ref="`otpInput${index}`"
                class="ml-1"
                type="Number"
                style="display: flex; justify-content: center; min-height: 100px; padding-left: 5px;"
                maxlength="1"
                @input="focusNext(index)"
              ></v-text-field>
            </div>
            <v-btn
              :loading="loading"
              x-large
              @click="approve"
              color="black"
              class="white--text"
              depressed
              rounded
              >Aprovar</v-btn
            >
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="5">
        <v-flex xs12 class="px-3" md6>
          <div
            style="min-height: 50vh;"
            class="expande-horizontal centraliza column"
          >
            <v-avatar size="100" color="green">
              <v-icon color="white" size="80">mdi-check</v-icon>
            </v-avatar>
            <span class="fonte-bold mt-6" style="font-size: 21pt;"
              >Pagamento Aprovado</span
            >
            <v-btn
              color="black"
              class="my-6"
              dark
              depressed
              rounded
              x-large
              @click="reset"
              >Novo Pagamento</v-btn
            >
            <v-btn color="black" text @click="$router.go(-1)">Sair</v-btn>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="6">
        <v-flex xs12 class="px-3" md6>
          <div
            style="min-height: 50vh;"
            class="expande-horizontal centraliza column"
          >
            <v-avatar size="100" color="red">
              <v-icon color="white" size="80">mdi-close</v-icon>
            </v-avatar>
            <span class="fonte-bold mt-6" style="font-size: 21pt;">{{
              error
            }}</span>
            <v-btn
              color="black"
              class="my-6"
              dark
              depressed
              rounded
              x-large
              @click="tryAgain"
              >Tentar Novamente</v-btn
            >
            <v-btn
              color="black"
              class="my-6 mt-0"
              dark
              depressed
              rounded
              x-large
              @click="$router.go(-1)"
              >sair</v-btn
            >
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      user: {},
      balance: {},
      step: "0",
      cpf: "",
      error: "",
      transfer_value: "",
      loading: false,
      otp: ["", "", "", "", "", ""], // Array para armazenar os dígitos do OTP
      otpLength: 6, // Tamanho do código OTP
      transactions: [],
      unilevel: [],
      opt: "",
      getAmount: false,
      payment_method: "scn",
      installment_number: 1
    };
  },
  methods: {
    ...mapActions([
      "getDestinationUser",
      "createRequestPromise",
      "approveRequestPromise"
    ]),
    getDestUser() {
      this.loading = true;
      console.log("asd", this.cpfFormatted);
      this.getDestinationUser({
        cpf: this.cpfFormatted,
        transaction_type: this.transaction_type
      }).then(res => {
        this.loading = false;
        this.user = res.user;
        this.balance = res.balance;
        this.step = "2";
        this.$refs.userName.focus();
      });
    },
    confirmDestUser() {
      if (!this.user) {
        alert("Por favor, informe o CPF");
        return;
      }
      this.step = "3";
    },
    createRequest() {
      if (parseFloat(this.transfer_value) < 1) {
        alert("O valor deve ser 1 real ou maior");
        return;
      }
      if (!this.transfer_value) {
        alert("Por favor, informe o valor");
        return;
      }
      if (this.getAmount) {
        this.startTerminalValidation(this.payment_method);
      } else {
        this.loading = true;
        this.createRequestPromise({
          value: this.transfer_value.replace(",", "."),
          user: {
            _id: this.user._id
          }
        }).then(res => {
          this.step = "4";
          this.loading = false;
          this.transactions = res.promises;
          this.unilevel = res.unilevel;
          setTimeout(() => {
            this.$refs["otpInput0"][0].focus();
          });
        });
      }
    },
    focusNext(index) {
      if (index === 5) {
        this.loading = true;
        this.approve();
      } else if (index < this.otpLength - 1 && !this.loading) {
        const nextInput = this.$refs[`otpInput${index + 1}`];
        nextInput[0].focus();
      }
    },
    tryAgain() {
      this.otp = ["", "", "", "", "", ""];
      setTimeout(() => {
        if (this.payment_method == "scn") {
          this.step = "4";
        } else {
          this.reset();
        }
      }, 300);
      setTimeout(() => {
        this.$refs.otpInput0[0].focus();
      }, 500);
    },
    printLogoBMP(text) {
      return new Promise((resolve, reject) => {
        window.PinBankPlugin.printText(
          [``, text ? `logo` : "l", ` `],
          (s) => {
            resolve(s);
          },
          (e) => {
            reject(e);
          }
        );
      });
    },
    approve() {
      this.loading = true;
      this.approveRequestPromise({
        code: this.code,
        transactions: this.transactions,
        unilevel: this.unilevel,
        user: {
          _id: this.user._id
        }
      })
        .then(async res => {
          this.loading = false;
          if (res.message == "Senha inválida") {
            this.error = "Senha incorreta";
            this.step = "6";
            setTimeout(() => {
              this.$refs.optInput6.focus();
            }, 300);
          } else {
            this.step = "5";
            window.PinBankPlugin.printText(
              [
                `REVOLUTION INTERNATIONAL BANK\nVIA DO CLIENTE \n------------------------------- \nEnd: Av. Paulista, 1471 \nConjunto 511, Bela Vista \nCEP 01.311-927, São Paulo - SP \n${this.$moment().format(
                  "llll"
                )} \n------------------------------- `,
                `\nCLIENTE: ${
                  this.user.name.split(" ")[0]
                } \nMEIO DE PAGAMENTO: REVOLUTION \nVALOR: ${this.$helper.formataSaldo(
                  `${this.transfer_value}`.replace(",", ".")
                )} \n\n------------------------------- \n\n`,
                `${this.user.nickname}-000${this.user.id_user}`
              ],
              //success
              () => {
                // console.log("")
              },
              error => {
                console.log(error);
              }
            );
          }
        })
        .catch(e => {
          this.error = e.response.data.message;
          this.loading = false;
          this.step = "6";
          setTimeout(() => {
            this.$refs.optInput6.focus();
          }, 300);
        });
    },
    reset() {
      this.otp = ["", "", "", "", "", ""];
      this.transactions = [];
      this.error = "";
      this.transfer_value = "";
      this.user = {};
      this.balance = {};
      this.cpf = "";
      this.step = "0";
      this.installment_number = 1;
      this.payment_method = "scn";
      this.getAmount = false;
    },
    initializeSdk() {
      window.PinBankPlugin.initializeSdk(
        "Hello",
        result => {
          console.log("Success:" + result);
        },
        error => {
          console.log(error);
        }
      );
    },
    preSdk(payment_method) {
      this.payment_method = payment_method;
      this.getAmount = true;
      this.step = "3";
    },
    startSaleDebit() {
      const value = this.transfer_value.replace(",", ".");
      window.PinBankPlugin.startSaleDebit(
        value,
        () => {
          this.step = "5";
        },
        error => {
          this.error = error;
          this.step = "6";
          this.reset();
        }
      );
    },
    startSaleCredit() {
      const value = this.transfer_value.replace(",", ".");
      const installment_number = parseInt(this.installment_number || 1);
      window.PinBankPlugin.startSaleCredit(
        [value, installment_number],
        () => {
          this.step = "5";
        },
        error => {
          this.error = error;
          this.step = "6";
          this.reset();
        }
      );
    },
    startPixTransactionWithAmount() {
      const value = this.transfer_value.replace(",", ".");
      window.PinBankPlugin.startPixTransactionWithAmount(
        value,
        () => {
          this.step = "5";
        },
        error => {
          console.log(error);
          this.error = error;
          this.step = "6";
        }
      );
    },
    startTerminalValidation(type) {
      window.PinBankPlugin.startTerminalValidation(
        "Hello",
        () => {
          if (type == "pix") {
            this.startPixTransactionWithAmount();
          }
          if (type == "credit") {
            this.startSaleCredit();
          }
          if (type == "debit") {
            this.startSaleDebit();
          }
          this.getAmount = false;
        },
        error => {
          console.log(error);
        }
      );
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"]),
    paymentMethodSelected() {
      let methods = {
        pix: { name: "PIX", type: "pix" },
        credit: { name: "Crédito", type: "credit" },
        debit: { name: "Debito", type: "debit" },
        scn: { name: "SCN", type: "scn" }
      };
      if (this.step == "0") return "Solicitar Pagamento";
      return methods[this.payment_method].name;
    },
    code() {
      let code = this.otp.join("");
      return code;
    },
    cpfFormatted() {
      let cpf = "";
      if (this.cpf) {
        cpf = this.cpf
          .replace(/\D/g, "")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      }
      return cpf;
    }
  },
  created() {
    this.initializeSdk();
  }
};
</script>
