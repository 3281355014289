import http from "@/apps/shared/baseService/config";

class Service {
  listBalanceToken() {
    return http.post('/user/user/list-balance-token')
  }
  listBalanceUnilevel() {
    return http.post('/user/user/list-balance-unilevel-stablishment')
  }
  listBalanceInvestment() {
    return http.post('/user/user/list-balance')
  }
  listRanking() {
    return http.post('/user/user/list-ranking')
  }
  updateProfile(payload) {
    return http.post('/user/user/update-profile', payload)
  }
  listMonthlyPayment(payload) {
    return http.post('/user/user/list-monthly-payment', payload)
  }
  listRequestPayment(payload) {
    return http.post('/user/user/list-request-payment', payload)
  }
  requestPayment(payload) {
    return http.post('/user/user/request-payment-stablishment', payload)
  }
  transferBalance(payload) {
    return http.post('/user/user/transfer-balance', payload)
  }
  listMyAccountBanks() {
    return http.post('/user/account-bank/list')
  }
  createAccountBank(payload) {
    return http.post('/user/account-bank/create', payload)
  }
  updateAccountBank(payload) {
    return http.post('/user/account-bank/update', payload)
  }
  getBalancePurchaseInvestment(payload) {
    return http.post('/user/user/get-balance-purchase-investment', payload)
  }
  getDestinationUser(payload) {
    return http.post('/user/user/get-destination-user', payload)
  }
  requestStorePayment(payload) {
    return http.post('/user/user/request-store-payment', payload)
  }
  generateCode(payload) {
    return http.post('/user/user/generate-otp-code', payload)
  }
  createRequestPromise(payload) {
    return http.post('/user/user/create-sale-promise', payload)
  }
  approveRequestPromise(payload) {
    return http.post('/user/user/validate-otp-code', payload)
  }
  //for scncommerce
  createRequestCredential() {
    return http.post('/admin/tenant/create-request-credential')
  }
}

export default new Service();
