<template>
  <div class="expande bg">
    <h1 class="fonte-bold">Quase lá, defina uma senha de acesso!</h1>
    <v-card outlined class="pa-4" width="600">
      <v-form ref="form">
        <v-flex xs12>
          <v-text-field
            :rules="[v => !!v || 'Preencha aqui']"
            solo-inverted
            dense
            flat
            v-model="password"
            label="Informe a senha aqui"
            :color="$primaria"
          />
        </v-flex>
      </v-form>
      <v-flex xs12>
        <v-btn
          block
          class="white--text"
          color="black"
          x-large
          @click="validate"
        >
          Ativar agora <v-icon class="mb-1" size="20">mdi-check</v-icon>
        </v-btn>
      </v-flex>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      password: ""
    };
  },
  methods: {
    ...mapActions(["activateAccount"]),
    validate() {
      if (this.$refs.form.validate()) {
        this.activateAccount({
          token: this.id,
          password: this.password
        });
      }
    }
  }
};
</script>

<style>
.expande {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<!-- <template>
  <div class="expande-horizontal centraliza" style="height: 100vh;">
    <v-icon :color="$theme.primary" class="mr-2" size="50"
      >mdi-account-circle</v-icon
    >
    <h4 class="text-center fonte orange--text">Ativando conta...</h4>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["id"],
  methods: {
    ...mapActions(["activateAccount"])
  }
  // created() {
  //     this.activateAccount(this.id)
  // }
};
</script> -->
