import http from "@/apps/shared/baseService/config";

class Service {
  auth(payload) {
    return http.post(`/signin-stablishment`, payload);
  }

  signup(payload) {
    return http.post(`/signup-stablishment/${payload.sponsor._id}`, payload);
  }

  verifySponsor(sponsor_id) {
    return http.post(`/sponsor/${sponsor_id}`);
  }
  
  forgotPassword(email) {
    return http.post(`/forgot-password`, email);
  }
  
  forgotPasswordFire(payload) {
    return http.post(`/forgot-password/${payload.token}`, payload);
  }
  
  activateAccount(payload) {
    return http.post(`/activate-account/${payload.token}`, payload);
  }
  verifyProfile(payload) {
    return http.post(`/verify-profile/${payload}`);
  }
}

export default new Service();
