<template>
  <layout :title="$route.name" :options="[]" :toolbar_off="true">
    <div class="expande-horizontal wrap">
      <v-flex xs12 class="pa-3 pb-0">
        <div class="expande-horizontal centraliza">
          <img
            class="img-logo"
            height="40"
            src="logo.jpeg"
            style="border-radius: 6px;"
          />
          <v-spacer></v-spacer>
          <!-- <div class="expande-horizontal column title-item">
            <span style="font-size: 16pt;" class="fonte-bold">SCN</span>
            <span class="fonte grey--text">Bem vindo(a)</span>
          </div> -->
          <div>
            <!-- <v-avatar
              @click="$router.push('/logged-user')"
              size="31"
              style="cursor: pointer;"
              color="#e5e5e5"
              icon
            >
              <v-icon size="21">mdi-account</v-icon>
            </v-avatar> -->
            <v-menu left offset-y rounded bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon v-if="!profile_image" color="black"
                    >mdi-account-circle-outline</v-icon
                  >
                  <v-avatar v-else>
                    <img height="35" contain :src="profile_image" />
                  </v-avatar>
                </v-btn>
              </template>

              <v-list style="min-width: 300px;" class="fonte px-0 mx-0" dense>
                <v-list-item class="mx-0 px-2" to="/logged-user">
                  <v-avatar
                    v-if="!profile_image"
                    size="35"
                    color="#e5e5e5"
                    class="mr-2"
                  >
                    <v-icon size="19">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-avatar class="mr-2" size="35" v-else>
                    <v-img :src="profile_image"></v-img>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Meu perfil</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      getLoggedUser.name
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mx-0 px-2" to="/config">
                  <v-avatar size="35" color="#e5e5e5" class="mr-2">
                    <v-icon size="19">mdi-cog-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Configurações</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Foto de perfil e senha.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="logout" class="mx-0 px-2">
                  <v-avatar size="35" color="#e5e5e5" class="mr-2">
                    <v-icon size="19">mdi-run</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 11pt;"
                      class="fonte-bold"
                      >Sair</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >desconecta a sua conta</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <span class="fonte-bold text-uppercase">
              {{  firstName  }}
            </span> -->
          </div>
        </div>
      </v-flex>
      <v-flex xs12>
        <div class="expande-horizontal pa-3 px-3" style="justify-content: flex-start; align-items: flex-start;">
          <div
            @click="$router.push('/transferencia/unilevel')"
            class="centraliza column mr-10"
            style="display: flex; min-width: 60px;cursor: pointer;"
            v-if="
              getLoggedUser.admin ||
              (getLoggedUser.profile_permission && getLoggedUser.profile_permission.transferencias)
            "
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <!-- <v-icon size="30">mdi-qrcode-scan</v-icon> -->
              <v-icon size="30">mdi-cash-fast</v-icon>
            </div>
            <span class="fonte-bold">Transferir</span>
          </div>
          <div
            v-if="
              getLoggedUser.admin ||
              (getLoggedUser.profile_permission && getLoggedUser.profile_permission.cobrancas)
            "
            @click="$router.push('/solicitar-pagamento/unilevel')"
            class="centraliza column mr-6"
            style="display: flex; width: 60px;cursor: pointer;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <!-- <img height="60" src="logo_mini.png" alt=""> -->
              <v-icon size="30">mdi-cash-register</v-icon>
            </div>
            <span class="fonte-bold">Receber</span>
          </div>

          <!-- <div
            @click="$router.push('/transactions')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-receipt-text-outline</v-icon>
            </div>
            <span class="fonte-bold">Extratos</span>
          </div> -->
          <!-- <div
            @click="$router.push('/deposito/unilevel')"
            class="centraliza column mr-4"
            style="display: flex; width: 60px;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-cash-plus</v-icon>
            </div>
            <span class="fonte-bold">Depósito</span>
          </div> -->
          <div
            v-if="getLoggedUser.admin"
            @click="$router.push('/estabelecimentos')"
            class="centraliza column mr-4"
            style="display: flex; min-width: 60px;cursor: pointer;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-storefront</v-icon>
            </div>
            <span class="fonte-bold text-center">Dados da empresa</span>
          </div>
          <div
            v-if="getLoggedUser.admin"
            @click="$router.push('/funcionarios')"
            class="centraliza column mr-1"
            style="display: flex; min-width: 60px; cursor: pointer;"
          >
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-account-group</v-icon>
            </div>
            <span class="fonte-bold" style="white-space: nowrap;"
              >Perfis de Acesso</span
            >
          </div>
          <!-- <div @click="$router.push('/solicitar-pagamento/unilevel')" class="centraliza column mr-3" style="display: flex; width: 60px;">
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-cash-register</v-icon>
            </div>
            <span class="fonte-bold">Receber</span>
          </div> -->
          <!-- <div @click="$router.push('/estabelecimentos')" class="centraliza column" style="display: flex; width: 60px;">
            <div
              class="centraliza"
              style="border-radius: 12px; background-color: #e5e5e5; display: flex; width: 60px; height: 60px;"
            >
              <v-icon size="30">mdi-storefront-outline</v-icon>
            </div>
            <span class="fonte-bold">Empresas</span>
          </div> -->
        </div>
      </v-flex>
      <v-flex
        v-if="getLoggedUser.admin"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <CriarContaPinbank />
      </v-flex>
      <v-flex
        v-if="getLoggedUser.admin"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <Tools />
      </v-flex>
      <v-flex
        v-if="getLoggedUser.admin || (getLoggedUser.profile_permission && getLoggedUser.profile_permission.saldo)"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <SaldoContabil />
      </v-flex>
      <v-flex
        v-if="getLoggedUser.admin || (getLoggedUser.profile_permission && getLoggedUser.profile_permission.saldo)"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <SaldoDisponivel />
      </v-flex>
      <v-flex
        v-if="getLoggedUser.admin || (getLoggedUser.profile_permission && getLoggedUser.profile_permission.saques)"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <SaldoSaques />
      </v-flex>
      <!-- <v-flex
        v-if="show_token"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'"
      >
        <Tokens />
      </v-flex> -->
      <!-- <v-flex v-if="show_unilevel" xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <PlanoDeCarreira />
      </v-flex> -->
      <!-- <v-flex
        v-if="show_unilevel"
        xs12
        md6
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'"
      >
        <Unilevel />
      </v-flex> -->
      <!-- <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <Notificacoes />
      </v-flex> -->
      <!-- <v-flex
        v-if="getLoggedUser.primeira_compra"
        xs12
        md4
        :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'"
      > -->
      <!-- <v-flex xs12 md6 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-3'">
        <Link />
      </v-flex> -->
      <!-- <v-flex xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <TotalGain />
      </v-flex> -->
      <!-- <v-flex v-if="show_unilevel" xs12 md4 :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-6'">
        <PlanExpiresIn />
      </v-flex> -->
      <!-- <v-dialog width="300" v-model="modal_unread">
        <v-flex xs12>
          <v-card class="expande-horizontal wrap">
            <v-flex class="pa-3" xs12>
              <div class="expande-horizontal centraliza">
                <v-icon color="green"> mdi-bell-ring </v-icon>
              </div>
            </v-flex>
            <v-flex xs12>
              <h3 class="fonte text-center pa-3">
                {{ total_unreads }} notificações não lidas
              </h3>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal fonte pa-3 centraliza wrap">
                <v-btn
                  :color="$theme.primary"
                  @click="$router.push('/notifications')"
                  >Ir para notificações</v-btn
                >
              </div>
            </v-flex>
          </v-card>
        </v-flex>
      </v-dialog> -->
      <v-flex
        v-if="getLoggedUser.admin || (getLoggedUser.profile_permission && getLoggedUser.profile_permission.saldo)"
        xs12
      >
        <div class="expande-horizontal pa-2 wrap">
          <v-flex xs12>
            <div class="expande-horizontal">
              <h3 class="fonte-bold pa-3">Extrato de vendas</h3>
            </div>
          </v-flex>
          <v-list
            color="transparent"
            style="min-width: 100%;"
            class="pa-0 ma-0"
            three-line
          >
            <template v-for="(item, index) in getTransactions">
              <v-list-item :key="item._id">
                <v-list-item-content>
                  <v-list-item-title class="fonte-bold">
                    {{ item.description }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="fonte">
                    {{ $helper.formataSaldo(item.value) }}
                    <v-chip
                      x-small
                      style="border-radius: 6px;"
                      class="mx-2 fonte-bold"
                      dark
                      :color="getStatus(item.current_status).color"
                      >{{ getStatus(item.current_status).text }}</v-chip
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="font-weight-light"
                    style="font-size: 8pt;"
                  >
                    {{
                      $moment(item.created_at)
                        .locale("pt-br")
                        .format("llll")
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
          <div
            class="expande-horizontal pa-2"
            v-if="getTransactions.length == 0"
          >
            <span class="fonte grey--text">Sem movimentação!</span>
          </div>
        </div>
      </v-flex>
      <v-dialog
        persistent
        fullscreen
        v-model="modal_create_stablishment"
        max-width="600"
      >
        <div
          :class="{ 'px-12': $vuetify.breakpoint.lgAndUp }"
          class="expande-horizontal vitrify-content centraliza"
          style="min-height: 100vh;"
        >
          <ModalCreateStablishment />
        </div>
      </v-dialog>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Saldo from "./components/Saldo";
import CriarContaPinbank from "./components/CriarContaPinbank";
import SaldoDisponivel from "./components/SaldoDisponivel";
import SaldoContabil from "./components/SaldoContabil";
import SaldoSaques from "./components/SaldoSaques";
import PlanoDeCarreira from "./components/PlanoDeCarreira";
import Tokens from "./components/Tokens";
import Unilevel from "./components/Unilevel";
import Notificacoes from "./components/Notificacoes";
import ChartSaldo from "./components/ChartSaldo";
import Link from "./components/Link";
import PlanExpiresIn from "./components/PlanExpiresIn";
import Graduacao from "./components/Graduacao";
import TotalGain from "./components/TotalGain";
import Tools from "./components/Tools";
import ModalCreateStablishment from "@/apps/Establishment/components/ViewForm";

export default {
  data() {
    return {
      // show_unilevel: process.env.VUE_APP_LOJA_UNILEVEL,
      show_unilevel: true,
      show_token: process.env.VUE_APP_LOJA_TOKEN,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      modal_unread: false,
      total_unreads: 0,
      modal_create_stablishment: false
    };
  },
  components: {
    Graduacao,
    Saldo,
    SaldoDisponivel,
    SaldoContabil,
    PlanoDeCarreira,
    Tokens,
    Unilevel,
    Notificacoes,
    ChartSaldo,
    CriarContaPinbank,
    Link,
    PlanExpiresIn,
    TotalGain,
    SaldoSaques,
    ModalCreateStablishment,
    Tools,
  },
  computed: {
    ...mapGetters([
      "getDashboard",
      "getLoggedUser",
      "getPurchases",
      "get_notifications",
      "getBalanceUnilevel",
      "getTransactions"
    ]),
    profile_image() {
      const user = JSON.parse(localStorage.getItem("user"));
      const img_profile = user ? user.profile_image : false;
      return user ? img_profile : this.getLoggedUser.profile_image || "";
    },
    firstName() {
      if (this.getLoggedUser) {
        return this.getLoggedUser.name.split(" ")[0];
      }
      return "";
    }
  },
  methods: {
    ...mapActions([
      "listBalanceInvestment",
      "listBalanceToken",
      "listBalanceUnilevel",
      "list_notifications",
      "listPurchases",
      "listUnilevels",
      "logout",
      "listTransactions",
      "listar_establishments"
    ]),
    getStatus(status) {
      switch (status) {
        case "waiting":
          return {
            text: "Aguardando",
            color: "orange"
          };
        case "active":
          return {
            text: "Disponível",
            color: "green"
          };
        case "requested":
          return {
            text: "Não consolidada",
            color: "red"
          };
        default:
          return {
            text: "Outros",
            color: "black"
          };
      }
    },
    openModalCreateStablichment() {
      this.modal_create_stablishment = true;
    },
    getColorByPurchaseProgress(purchase) {
      if (purchase.total_percent_gained === purchase.item.total_percent_gain) {
        return "orange";
      }

      if (
        purchase.total_percent_gained &&
        purchase.total_percent_gained < purchase.item.total_percent_gain
      ) {
        return "green";
      }

      if (purchase.total_percent_gained === 0) {
        return "red";
      }
    },
    verifyNotifications() {
      const notifications = this.get_notifications.docs;
      let total_unreads = 0;

      if (!localStorage.notification_showed) {
        notifications.map(notify => {
          console.log("ok");
          if (!notify.readed) {
            total_unreads += 1;
          }
        });
        localStorage.notification_showed = total_unreads;
        this.modal_unread = true;
        this.total_unreads = total_unreads;
      } else {
        this.total_unreads = localStorage.notification_showed;
      }
    }
  },
  created() {
    // this.listBalanceInvestment();
    // this.listBalanceToken();
    // this.listBalanceUnilevel();
    // this.listUnilevels();
    // this.listPurchases();
    this.listBalanceUnilevel();
    // this.listTransactions();
    this.listar_establishments().then(res => {
      if (res.data.docs.length === 0) {
        if (this.getLoggedUser.admin) {
          this.openModalCreateStablichment();
        }
      } else {
        this.$store.commit("set_establishment", res.data.docs[0]);
        this.modal_create_stablishment = false;
      }
    });
    // this.list_notifications();
    this.listTransactions({ all: true });
    // setInterval(() => {
    //   this.verifyNotifications();
    // }, 3000);
  }
};
</script>

<style>
.card-graduation {
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.img-logo {
  filter: invert(97%);
}
</style>
