import TransactionService from "../service";
import { router } from "@/router";

export const actions = {
  listTransactions({ commit, dispatch }, data) {
    dispatch("enableLoading");

    TransactionService.list(data)
      .then(res => {
        dispatch("disableLoading");
        commit("setTransactions", res.data);
      })
      .catch(e => {
        dispatch("disableLoading");
        dispatch("createGlobalMessage", {
          type: "error",
          message: e.response.data.message,
          timeout: 6000
        });
      });
  }
};

export default {};
