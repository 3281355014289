<template>
  <layout title="Funcionários" :options="options" :rotas="rotas">
    <v-sheet
      :dark="$theme.dark"
      :light="$theme.light"
      class="expande-horizontal sheet-container pt-3"
    >
      <v-flex xs12 class="pr-3" lg3>
        <Filters :filter="filter" />
        <List :filtereds="filtereds" />
        <EmptyList
          :iniciarCadastro="iniciarCadastro"
          v-if="filtereds.length === 0"
        />
      </v-flex>
      <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
        <ViewForm v-if="get_funcionario.new || get_funcionario._id" />
      </v-flex>
      <v-dialog
        v-else
        transition="slide-x-transition"
        fullscreen
        :value="get_funcionario._id || get_funcionario.new ? true : false"
      >
        <v-card>
          <ViewForm />
        </v-card>
      </v-dialog>
    </v-sheet>
  </layout>
</template>

<script>
import EmptyList from "../components/EmptyList.vue";
import Filters from "../components/Filters.vue";
import List from "../components/List.vue";
import ViewForm from "../components/ViewForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      options: [
        {
          nome: "Novo funcionario",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "Colaboradores",
          path: "/funcionarios"
        }
      ]
    };
  },
  components: {
    EmptyList,
    Filters,
    List,
    ViewForm
  },
  computed: {
    ...mapGetters([
      "get_funcionarios_filtros",
      "get_permissions_filtros",
      "getLoggedUser",
      "get_funcionario",
      "get_funcionarios",
      "getPersonalizedLoading"
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_funcionarios.filter(item => {
          return (
            (item.nome &&
              item.nome
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.email &&
              item.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.telefone &&
              item.telefone.includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_funcionarios;
      }
    }
  },
  methods: {
    excluir_funcionario_dialog(item) {
      let text = "Deseja remover este funcionario?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_funcionario",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_funcionarios",
      "criar_estoque",
      "listar_funcionariocategorias",
      "criar_funcionario",
      "list_permissions",
      "listar_estoques",
      "atualizar_funcionario",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    criarOuAtualizar() {
      if (this.get_funcionario._id) {
        this.atualizar_funcionario();
      } else {
        this.criar_funcionario();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_funcionario", {
        new: true,
        profile_permission: {}
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_funcionario._id) {
          this.atualizar_funcionario();
        } else {
          this.criar_funcionario();
        }
      }
    }
  },
  created() {
    // this.get_permissions_filtros.all = true;
    // this.list_permissions();
    this.listar_funcionarios();
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 96vh;
  max-height: 96vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
