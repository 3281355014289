<template>
  <v-container
    style="flex-direction: column; background: rgba(0,0,0,0) "
    fluid
    class="molde pa-0 ma-0"
  >
    <v-layout column>
      <v-app-bar
        app
        dense
        v-if="!toolbar_off"
        :fixed="$vuetify.breakpoint.smAndDown"
        class="elevation-0"
        color="#fff"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'margin-bottom: 0px; border-radius: 0px'
            : 'border-radius: 0px;margin-bottom: 0px'
        "
        style="justify-content: center; align-items: center;"
      >
        <!-- <v-btn @click="drawer = !drawer" icon>
          <v-icon :color="$theme.primary">mdi-menu</v-icon>
        </v-btn> -->
        <v-btn class="mr-2" @click="$router.go(-1)" icon>
          <v-avatar size="34" color="#e5e5e5" class="elevation-0">
            <v-icon size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </v-btn>

        <!-- <img width="20" height="20" :src="$theme.logo" /> -->

        <span class="fonte-bold pt-1" :style="`font-size: 21pt;`">{{
          title
        }}</span>

        <v-spacer></v-spacer>

        <!-- <v-btn small rounded class="ma-0 pa-0 px-1" :color="$theme.primary" outlined>
          <v-avatar size="21">
            <v-img :src="$theme.token_img"></v-img>
          </v-avatar>
          <h3 class="ml-3 fonte"> {{ getBalanceToken.balance }}</h3>
        </v-btn> -->

        <!-- <v-flex v-if="$vuetify.breakpoint.lgAndUp" class="pt-4 px-2" md3>
              <v-text-field solo-inverted dark flat dense v-model="pesquisa" placeholder="Faça uma busca" :color="$theme.primary" append-icon="mdi-magnify"></v-text-field>
          </v-flex> -->

        <!-- <v-btn small v-for="item in options" :color="$theme.primary" class="elevation-0 hidden-sm-and-down animated flipInX delay-1s mr-1" @click="item.action()" :key="item.nome">
              <v-icon class="mr-1" color="#fff"> {{ item.icon }} </v-icon>
              <span class="white--text text-capitalize fonteCash" >{{ item.nome}}</span> 
          </v-btn> -->

        <v-menu v-if="false" bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon :color="$theme.primary">mdi-bell-outline</v-icon>
            </v-btn>
          </template>

          <v-list width="300">
            <v-list-item
              v-for="notification in get_notifications.docs"
              :key="notification._id"
              @click="abre_modal_view_notification(notification)"
            >
              <v-list-item-avatar>
                <v-icon>mdi-bell-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="fonte">{{
                  notification.title
                }}</v-list-item-title>
                <v-list-item-subtitle class="fonte">{{
                  notification.message
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="orange--text fonte"
                  style="font-size: 9pt;"
                  >{{
                    $moment(notification.created_at).format("llll")
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="black">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>

          <v-list nav dense>
            <v-list-item to="/logged-user">
              <v-list-item-title>{{ getLoggedUser.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="options" left bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="item in options"
              :key="item.nome"
              @click="() => item.action()"
            >
              <v-list-item-title>{{ item.nome }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-navigation-drawer
        :bordered="false"
        app
        v-if="false"
        color="#f2f2f2"
        width="250"
        class="elevation-2"
        v-model="drawer"
      >
        <v-list nav dense>
          <v-list-item
            active-class="menuAtivoHome"
            to="/dashboard"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/dashboard' ? 'grey--text' : $theme.primary
              "
            >
              mdi-grid
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/dashboard' ? 'grey--text' : 'grey--text'
                  "
                  >Dashboard</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            active-class="menuAtivoHome"
            to="/estabelecimentos"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/estabelecimentos'
                  ? 'black--text'
                  : $theme.primary
              "
            >
              mdi-storefront
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/estabelecimentos'
                      ? 'black--text'
                      : 'black--text'
                  "
                  >Estabelecimentos</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            active-class="menuAtivoHome"
            to="/notifications"
            class="ml-0 pl-0"
          > -->
          <v-list-item
            v-if="false"
            active-class="menuAtivoHome"
            to="/notifications"
            class="ml-0 pl-0"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/notifications'
                  ? 'white--text'
                  : $theme.primary
              "
            >
              mdi-bell-outline
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/notifications'
                      ? 'white--text'
                      : 'white--text'
                  "
                  >Notificações</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-icon size="16" class="mr-6" :color="$theme.primary">
                  mdi-store-outline
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">Loja</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            
          </v-list-group> -->

          <!-- <v-list-item
            v-if="show_ativos"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/investments"
          > -->
          <v-list-item
            v-if="false"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/investments"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="
                  $route.path === '/investments' ? 'white' : $theme.primary
                "
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/investments' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                Planos de Investimento
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            v-if="show_produtos"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/products"
          > -->
          <v-list-item
            v-if="false"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/products"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="$route.path === '/products' ? 'white' : $theme.primary"
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/products' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                {{ mb ? "Loja" : "Produtos" }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item
            v-if="show_planos_de_carreira"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/careers"
          > -->
          <v-list-item
            v-if="false"
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/careers"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="$route.path === '/careers' ? 'white' : $theme.primary"
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/careers' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                Planos de Ativação
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/purchases"
            v-if="false"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="$route.path === '/purchases' ? 'white' : $theme.primary"
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/purchases' ? 'white--text' : 'white--text'
                "
                class="fonteCash"
              >
                Minhas Compras
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="ml-0 pl-0"
            active-class="menuAtivoHome"
            to="/transactions"
          >
            <v-avatar>
              <v-icon
                size="16"
                class="ml-2 mr-6"
                :color="
                  $route.path === '/transactions' ? 'white' : $theme.primary
                "
              >
                mdi-chevron-right
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.path === '/transactions'
                    ? 'white--text'
                    : 'white--text'
                "
                class="fonteCash"
              >
                Meus Extratos
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            active-class="menuAtivoHome"
            to="/request-payment"
            class="ml-0 pl-0"
            v-if="false"
          >
            <v-icon
              size="16"
              class="ml-2 mr-6"
              :color="
                $route.path === '/request-payment'
                  ? 'white--text'
                  : $theme.primary
              "
            >
              mdi-cash-register
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  class="fonteCash fonteMini"
                  :class="
                    $route.path === '/request-payment'
                      ? 'white--text'
                      : 'white--text'
                  "
                  >Saque</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- rede -->
          <!-- <v-list-group v-if="show_unilevel" no-action :color="$theme.primary"> -->
          <v-list-group no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-icon size="16" class="mr-6" :color="$theme.primary">
                  mdi-network-outline
                </v-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">Rede</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/unilevel"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/unilevel' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/unilevel' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Minha Rede
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <!-- carreira -->
          <!-- <v-list-group v-if="show_token" no-action :color="$theme.primary"> -->
          <v-list-group v-if="false" no-action :color="$theme.primary">
            <template v-slot:activator>
              <v-list-item class="ml-0 pl-0">
                <v-avatar class="mr-6" size="16">
                  <v-img :src="$theme.token_img"> </v-img>
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="fonteCash fonteMini">Tokens</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/premiacoes"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/premiacoes' ? 'white' : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/premiacoes'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Graduações
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/ranking"
            >
              <v-avatar>
                <v-icon
                  :color="$route.path === '/ranking' ? 'white' : $theme.primary"
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/ranking' ? 'white--text' : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Ranking
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="pl-6"
              active-class="menuAtivoHome"
              to="/transfer-balance/token"
            >
              <v-avatar>
                <v-icon
                  :color="
                    $route.path === '/transfer-balance/token'
                      ? 'white'
                      : $theme.primary
                  "
                >
                  mdi-chevron-right
                </v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    $route.path === '/transfer-balance/token'
                      ? 'white--text'
                      : 'white--text'
                  "
                  class="fonteCash fonteMini"
                >
                  Transferir Token
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            active-class="menuAtivoHome"
            to="/logged-user"
            v-if="false"
            class="ml-0 pl-0"
          >
            <v-icon
              size="21"
              class="ml-2 mr-4"
              :color="$route.path === '/logged-user' ? 'white' : $theme.primary"
            >
              mdi-account-circle
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span class="fonteCash white--text fonteMini text-capitalize">
                  {{ getLoggedUser.name }}
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Configurações da conta
              </v-list-item-subtitle>
              <v-list-item-subtitle class="fonte grey--text">
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main class="molde">
        <slot></slot>
      </v-main>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["title", "options", "icon", "action", "origem", "toolbar_off"],
  data() {
    return {
      mb: process.env.VUE_APP_MB,
      show_unilevel: process.env.VUE_APP_LOJA_UNILEVEL,
      show_token: process.env.VUE_APP_LOJA_TOKEN,
      show_planos_de_carreira: process.env.VUE_APP_LOJA_CARREIRAS,
      show_produtos: process.env.VUE_APP_LOJA_PRODUTOS,
      show_ativos: process.env.VUE_APP_LOJA_ATIVOS,
      drawer: true
    };
  },
  computed: {
    ...mapGetters(["getLoggedUser", "get_notifications", "getBalanceToken"])
  },
  methods: {
    ...mapActions([
      "list_notifications",
      "logout",
      "abre_modal_view_notification"
    ]),
    goBack() {
      if (this.action !== undefined) {
        this.action();
      } else {
        this.$router.go(-1);
      }
    }
  },
  created() {
    this.drawer = this.$vuetify.breakpoint.smAndUp;
    // this.list_notifications();
  }
};
</script>

<style>
.v-navigation-drawer__border {
  display: none !important;
}
.centraliza-tudo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molde {
  display: flex;
  width: 100%;
  background: #f8f8f8;
  min-height: 100vh;
}
</style>
