<template>
  <div class="expande-horizontal centraliza wrap pt-12">
    <v-flex xs12>
      <div class="expande-horizontal centraliza">
        <img style="filter: invert(1);" height="40" src="logo.jpeg" />
      </div>
    </v-flex>
    <div class="terms-of-use">
      <h1>Termo de Uso Revolution – Plataforma SCN</h1>
      <p><strong>Última Atualização:</strong> 23 de junho de 2024.</p>
      <p>
        Bem-vindo(a)! Obrigado por utilizar a plataforma SCN! Esta aplicação e seu conteúdo são controlados e operados pela REVOLUTION, empresa inscrita no CNPJ/MF sob o no 27.659.353/0001-54, com sede em São Paulo/SP.
      </p>
      <p>
        Estes termos de uso têm por objeto definir as regras a serem seguidas para a utilização da plataforma SCN ("Termos de Uso"), sem prejuízo da aplicação da legislação vigente. Ao utilizar a plataforma SCN, você automaticamente concorda com estes termos de uso, responsabilizando-se integralmente por todos e quaisquer atos praticados por você na plataforma ou em serviços a ela relacionados. Caso você não concorde com qualquer dos termos e condições abaixo estabelecidos, você não deve utilizar a Plataforma SCN. Você também concorda com os termos descritos em nossa política de privacidade.
      </p>
      <p>
        Caso queira dar algum feedback sobre a SCN, tenha dúvidas ou precise tratar de qualquer assunto relacionado a estes Termos de Uso, entre em contato conosco através dos nossos canais:
      </p>
      <p>
        <a href="https://www.scnrevolution.com" target="_blank">www.scnrevolution.com</a><br>
        <a href="mailto:contato@scnrevolution.com">contato@scnrevolution.com</a>
      </p>
  
      <h2>ÍNDICE</h2>
      <ul>
        <li><a href="#o-que-e-a-scn">1. O que é a SCN?</a></li>
        <li><a href="#quem-esta-sujeito-a-estes-termos">2. Quem está sujeito a estes termos de uso?</a></li>
        <li><a href="#direitos-e-obrigacoes-dos-usuarios">3. Direitos e obrigações dos usuários.</a></li>
        <li><a href="#como-acesso-a-plataforma-scn">4. Como acesso a Plataforma SCN?</a></li>
        <li><a href="#relacao-contratual-entre-a-scn-e-os-usuarios">5. A relação contratual entre a SCN e os usuários.</a></li>
        <li><a href="#como-sao-feitos-os-pagamentos-na-scn">6. Como são feitos os pagamentos na SCN?</a></li>
        <li><a href="#quais-sao-os-direitos-da-scn-sobre-a-aplicacao">7. Quais são os direitos da SCN sobre a aplicação?</a></li>
        <li><a href="#propriedade-intelectual-sobre-o-software-e-os-materiais-disponibilizados">8. Propriedade intelectual sobre o software e os materiais disponibilizados.</a></li>
        <li><a href="#reclamacoes-sobre-a-violacao-de-direito-autoral">9. Reclamações sobre a violação de direito autoral.</a></li>
        <li><a href="#responsabilidade-do-usuario-e-da-scn">10. Responsabilidade do usuário e da SCN.</a></li>
        <li><a href="#como-a-scn-lida-com-o-conteudo-que-os-usuarios-produzem">11. Como a SCN lida com o conteúdo que os usuários produzem?</a></li>
        <li><a href="#o-que-mais-eu-preciso-saber-sobre-estes-termos-de-uso">12. O que mais eu preciso saber sobre estes termos de uso?</a></li>
      </ul>
  
      <section id="o-que-e-a-scn">
        <h2>1. O QUE É A SCN?</h2>
        <h3>1.1. Serviços</h3>
        <p>
          A SCN é uma sigla em inglês proveniente do termo “Smart Consumer Network” que significa REDE DE CONSUMO INTELIGENTE e compreende uma plataforma online de afiliados, fidelização, cashback e pagamentos que tem como objetivo ligar consumidores a comerciantes dos mais variados segmentos, entre prestadores de serviços e vendedores de produtos, criando uma comunidade de consumo inteligente com benefícios para todos. O consumo é realizado dentro da própria plataforma. Toda e qualquer operação de consumo realizada na plataforma gera cashback, que poderá ser utilizado para outras compras dentro da plataforma ou ser transferido para a conta digital da Revolution pelo usuário.
        </p>
        <h3>1.2. Suspensão</h3>
        <p>
          Nós nos reservamos ao direito de suspender ou cancelar, a qualquer momento, o acesso de quaisquer usuários à aplicação em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso, na Política de Privacidade ou na legislação aplicável. Nesses casos, não será devida qualquer indenização a você usuário, e a Revolution poderá promover a competente ação de regresso, bem como tomar outras medidas necessárias para resguardar seus interesses.
        </p>
      </section>
  
      <section id="quem-esta-sujeito-a-estes-termos">
        <h2>2. QUEM ESTÁ SUJEITO A ESTES TERMOS DE USO?</h2>
        <h3>2.1. Usuário Consumidor</h3>
        <p>
          Para os fins destes Termos de Uso, toda pessoa que se cadastrar na plataforma SCN para consumir produtos ou serviços disponibilizados por estabelecimentos comerciais fica desde já definido na plataforma SCN simplesmente como Usuário Consumidor.
        </p>
        <h3>2.2. Usuário Vendedor</h3>
        <p>
          Para os fins destes termos de uso, toda pessoa física ou jurídica que se cadastrar na plataforma SCN para vender produtos ou serviços, por meio de estabelecimento físico ou online será definido como Usuário Vendedor.
        </p>
        <h3>2.3. Dos Direitos e Obrigações</h3>
        <p>
          Usuário Consumidor e Usuário Vendedor, terão direitos e obrigações distintas perante a REVOLUTION e a Plataforma SCN, as quais serão estabelecidas individualmente nestes termos de uso. Qualquer um que não concordar com as previsões destes termos de uso, não poderá utilizar a plataforma SCN.
        </p>
      </section>
  
      <section id="direitos-e-obrigacoes-dos-usuarios">
        <h2>3. DIREITOS E OBRIGAÇÕES DOS USUÁRIOS DA PLATAFORMA SCN</h2>
        <h3>3.1. São direitos do Usuário Consumidor:</h3>
        <ul>
          <li>
            (I) Cadastrar-se na plataforma SCN para ter acesso aos produtos e serviços disponíveis;
          </li>
          <li>
            (II) Receber cashback de cada compra realizada na plataforma SCN por qualquer membro de sua rede de consumo, sendo: 1% de bonificação em seus indicados diretos no primeiro nível, 1% de bonificação em seus indicados indiretos no segundo nível, 2% de bonificação em seus indicados indiretos no terceiro nível e mais 2% de bonificação em seus indicados indiretos no quarto nível. Vale ressaltar, que o percentual de bonificação será pago de acordo com o consumo de cada membro equivalente a cada nível da rede, não sendo, portanto, os percentuais cumulativos;
          </li>
          <li>
            (III) Utilizar parte do seu cashback para continuar consumindo na plataforma SCN;
          </li>
          <li>
            (IV) Abrir uma conta digital no Revolution International Bank para poder transferir seu cashback recebido como bonificação pelo consumo de sua rede;
          </li>
        </ul>
        <h3>3.2. São obrigações do Usuário Consumidor:</h3>
        <ul>
          <li>
            (I) Reconhecer que a Plataforma SCN não será em hipótese alguma responsável por produtos ou serviços adquiridos na plataforma;
          </li>
          <li>
            (II) Cadastrar os dados de sua conta Revolution na plataforma SCN para que possa sacar seu cashback e comissões geradas por cada operação de consumo de sua rede.
          </li>
        </ul>
        <h3>3.3. São direitos do Usuário Vendedor:</h3>
        <ul>
          <li>
            (I) Receber todos os valores pelas vendas de produtos e/ou serviços realizadas através da Plataforma SCN, deduzidos da taxa de administração descrita no item 3.4.2;
          </li>
          <li>
            (II) Transferir tranquilamente para sua conta digital REVOLUTION, todos os seus valores recebidos por todas as vendas realizadas dentro da plataforma SCN;
          </li>
          <li>
            (III) Definir individualmente os preços dos produtos e/ou serviços oferecidos na plataforma.
          </li>
        </ul>
        <h3>3.4. São obrigações do Usuário Vendedor:</h3>
        <ul>
          <li>
            (I) Colocar à venda somente produtos e serviços que estejam em conformidade com as leis aplicáveis;
          </li>
          <li>
            (II) Pagar a taxa de administração sobre as vendas realizadas na plataforma SCN, conforme definido em item específico destes Termos de Uso;
          </li>
          <li>
            (III) Manter as informações de produtos e/ou serviços atualizadas e precisas;
          </li>
          <li>
            (IV) Manter um bom atendimento aos consumidores dentro da plataforma SCN, inclusive respeitando o prazo de entrega dos produtos vendidos.
          </li>
        </ul>
      </section>
  
      <section id="como-acesso-a-plataforma-scn">
        <h2>4. COMO ACESSO A PLATAFORMA SCN?</h2>
        <p>
          O acesso à plataforma SCN é realizado através de um login e senha criados pelo próprio usuário. O Usuário Consumidor e Usuário Vendedor são responsáveis por manter a confidencialidade de suas informações de login e senha, e devem notificar imediatamente a REVOLUTION caso haja suspeita de uso não autorizado de sua conta. A REVOLUTION não se responsabiliza por quaisquer danos resultantes da violação de segurança do login e senha do usuário.
        </p>
      </section>
  
      <section id="relacao-contratual-entre-a-scn-e-os-usuarios">
        <h2>5. A RELAÇÃO CONTRATUAL ENTRE A SCN E OS USUÁRIOS</h2>
        <h3>5.1. Usuário Consumidor</h3>
        <p>
          A relação entre o Usuário Consumidor e a SCN é regida por estes Termos de Uso, a Política de Privacidade e pela legislação aplicável. O Usuário Consumidor concorda que, ao utilizar a Plataforma SCN, está sujeito às condições de uso aqui estabelecidas e que não fará uso indevido da plataforma para obter qualquer vantagem ilícita ou não autorizada.
        </p>
        <h3>5.2. Usuário Vendedor</h3>
        <p>
          A relação entre o Usuário Vendedor e a SCN é regida por estes Termos de Uso, a Política de Privacidade, e pela legislação aplicável. O Usuário Vendedor compromete-se a fornecer informações verdadeiras e atualizadas sobre seus produtos e serviços e a cumprir com todas as obrigações descritas nos Termos de Uso.
        </p>
      </section>
  
      <section id="como-sao-feitos-os-pagamentos-na-scn">
        <h2>6. COMO SÃO FEITOS OS PAGAMENTOS NA SCN?</h2>
        <p>
          Os pagamentos realizados pelos consumidores na Plataforma SCN são processados diretamente pelos Usuários Vendedores. A REVOLUTION cobra uma taxa de administração sobre cada transação, conforme detalhado no item 3.4.2. A transferência dos valores para a conta digital REVOLUTION é realizada no prazo estabelecido pela REVOLUTION.
        </p>
      </section>
  
      <section id="quais-sao-os-direitos-da-scn-sobre-a-aplicacao">
        <h2>7. QUAIS SÃO OS DIREITOS DA SCN SOBRE A APLICAÇÃO?</h2>
        <p>
          A REVOLUTION detém todos os direitos sobre a Plataforma SCN, incluindo todos os direitos de propriedade intelectual, e o Usuário não adquire qualquer direito ou licença sobre a Plataforma SCN, exceto pelo direito de utilizar a plataforma de acordo com estes Termos de Uso. A REVOLUTION pode modificar, atualizar ou interromper a plataforma a qualquer momento, sem aviso prévio.
        </p>
      </section>
  
      <section id="propriedade-intelectual-sobre-o-software-e-os-materiais-disponibilizados">
        <h2>8. PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS DISPONIBILIZADOS</h2>
        <p>
          Todo o conteúdo e software disponível na Plataforma SCN são protegidos por direitos autorais e outras leis de propriedade intelectual. O Usuário concorda em não reproduzir, distribuir, modificar ou criar trabalhos derivados com base no conteúdo da plataforma sem a autorização expressa da REVOLUTION.
        </p>
      </section>
  
      <section id="reclamacoes-sobre-a-violacao-de-direito-autoral">
        <h2>9. RECLAMAÇÕES SOBRE A VIOLAÇÃO DE DIREITO AUTORAL</h2>
        <p>
          Caso você identifique qualquer conteúdo na Plataforma SCN que considere ser uma violação de direitos autorais, por favor, entre em contato com a REVOLUTION através dos canais de atendimento especificados para que possamos tomar as medidas necessárias.
        </p>
      </section>
  
      <section id="responsabilidade-do-usuario-e-da-scn">
        <h2>10. RESPONSABILIDADE DO USUÁRIO E DA SCN</h2>
        <p>
          O Usuário é responsável por qualquer conteúdo que publique ou transmita na Plataforma SCN, bem como por qualquer atividade realizada em sua conta. A REVOLUTION não se responsabiliza por quaisquer danos resultantes do uso inadequado da plataforma pelo Usuário ou por qualquer violação dos Termos de Uso por parte dos Usuários.
        </p>
      </section>
  
      <section id="como-a-scn-lida-com-o-conteudo-que-os-usuarios-produzem">
        <h2>11. COMO A SCN LIDA COM O CONTEÚDO QUE OS USUÁRIOS PRODUZEM?</h2>
        <p>
          O Usuário concede à REVOLUTION uma licença não exclusiva, isenta de royalties, e transferível para utilizar qualquer conteúdo publicado na Plataforma SCN. A REVOLUTION pode usar, modificar, reproduzir e distribuir esse conteúdo para fins de operação e promoção da plataforma.
        </p>
      </section>
  
      <section id="o-que-mais-eu-preciso-saber-sobre-estes-termos-de-uso">
        <h2>12. O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</h2>
        <h3>12.1. Alterações</h3>
        <p>
          A REVOLUTION se reserva o direito de modificar estes Termos de Uso a qualquer momento. As alterações serão publicadas na Plataforma SCN e a continuidade do uso da plataforma após tais alterações constitui aceitação das novas condições.
        </p>
        <h3>12.2. Lei Aplicável</h3>
        <p>
          Estes Termos de Uso são regidos pela legislação brasileira, e qualquer disputa relacionada a estes Termos de Uso será dirimida no foro da comarca de São Paulo/SP.
        </p>
        <h3>12.3. Indivisibilidade</h3>
        <p>
          Caso qualquer disposição destes Termos de Uso seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.
        </p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfUse',
}
</script>

<style scoped>
.terms-of-use {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
}

h1, h2, h3 {
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

section {
  margin-bottom: 20px;
}
</style>
