<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize(style="min-height:90vh;")
        div.page-bg.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; display: flex; width: 100%; justify-content: center;")
          v-flex(xs12 md6 v-if="$vuetify.breakpoint.lgAndUp")
              div.inflar(
                style="background-size: cover;background-position: center;background-image: url('https://scnrevolution.com.br/wp-content/uploads/2024/04/pagamento-de-tecnologia-facil-e-sem-fio-2-scaled.jpg');"
              )
          v-flex.pa-3(xs12 md6 v-show="passo===1")
              div.expande-horizontal.centraliza.column
                div.flex.expande-horizontal
                  v-btn(@click="$router.go(-1)" class="elevation-0" color="white")
                    v-icon(size="16") mdi-chevron-left
                    span.black--text.fonte.black--text Login
                v-flex(xs12).mt-12
                  div.expande-horizontal.centraliza
                    img( style="filter: invert(1);" height="30" :src="$theme.logo")
                v-flex(xs12).mt-12.pa-3
                  div.expande-horizontal.wrap.column
                    span.fonte-bold.black--text Bem Vindo(a).
                    span.fonte-bold.grey--text Vamos começar seu cadastro
                    
                    v-list(v-if="payload.sponsor_id !== 'nosponsor' && payload.sponsor && payload.sponsor._id !== 'nosponsor'" color="transparent" rounded nav width="350" style="border-radius: 12px;")
                        v-list-item.px-2.mx-0(style="border-radius: 6px; border: 1px solid #f2f2f2;")
                            v-avatar.mr-2(color="black" classe="elevation-1")
                                v-icon(v-if="!getSponsor.profile_image" color="#f2f2f2") mdi-account-circle-outline
                                v-img(v-else :src="getSponsor.profile_image")
                            v-list-item-content
                                v-list-item-title(class="fonte")
                                    h2.fonte-bold {{ getSponsor.name }}
                                v-list-item-subtitle 
                                    span.fonte.grey--text Convidou você
                    
                    v-flex 
                      div.expande-horizontal
                        v-text-field(
                          label="CPF"
                          large 
                          rounded 
                          filled
                          flat
                          v-model="payload.cpf"
                          v-mask="['###.###.###-##']"
                          color="black"
                          placeholder="ex: 000.000.000-00"
                          background-color="#f2f2f2"
                          :rules="[v => !!v || 'Preencha aqui']"
                        )

                    v-btn.fonte.mt-12(@click="verifyCpf" block x-large color="black")
                      span.fonte-bold.white--text Continuar Cadastro
                      v-icon(size="18" color="grey") mdi-chevron-right 
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===2")
              v-form(ref="form")
                  div.flex.horizontal-expand.centraliza(style="min-height: 90vh; align-items: center;")
                      //- div.flex.expande-horizontal.centraliza
                        //- img(height="120" :src="$theme.logo")
                      div.expande-horizontal.centraliza
                        span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Cadastro
                        v-spacer
                        v-btn(icon @click="$router.go(-1)" class="elevation-1" color="black")
                          v-icon mdi-close
                      div.flex.horizontal-expand.column.mt-0
                          v-flex(xs12)
                              span.fonte.black--text Nome
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.name"
                                  placeholder="ex: Marinaldo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Nickname (usado para login e indicações)
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             light
                      //-             v-mask="['XXXXXXXXXXXXXXXXX']"
                      //-             v-model="payload.nickname"
                      //-             placeholder="ex: naldo"
                      //-             :rules="[v => !!v || 'Preencha aqui']"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.email"
                                  placeholder="ex: meu_melhor_email@gmail.com"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Nome da mãe
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.mother_name"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.cpf"
                                  readonly
                                  placeholder="ex: 000.000.000-00"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['###.###.###-##']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                              div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text CEP
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.cep"
                                  placeholder="ex: 68903-022"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['#####-###']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )

                          v-flex(xs12 v-if="address_ok")
                            div.expande-horizontal.px-0
                              v-list-item.px-0
                                v-avatar
                                  v-icon mdi-map
                                v-list-item-content 
                                  v-list-item-title.fonte-bold Endereço:
                                  v-list-item-subtitle 
                                    span.fonte {{ payload.address.logradouro }}, {{ payload.address.bairro }}, {{ payload.address.localidade }} - {{ payload.address.uf }}
                          v-flex(xs12 v-if="address_ok")
                            div.expande-horizontal.px-0
                              v-flex(xs12)
                                v-text-field(
                                    dense
                                    light
                                    ref="houseNumber"
                                    prefix="Número da casa:"
                                    v-model="payload.address.numero"
                                    placeholder="ex: 1235"
                                    :rules="[v => !!v || 'Preencha aqui']"
                                    :color="$theme.primary"
                                    solo-inverted
                                    color="#f2f2f2"
                                    flat
                                )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Um celular para contato
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.phone"
                                  placeholder="ex: (96) 98113-1234"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['(##) # #### ####']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.black--text Senha para transações
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             light
                      //-             v-model="payload.transaction_password"
                      //-             :rules="[v => !!v || 'Preencha este campo']"
                      //-             placeholder="****"
                      //-             v-mask="['####']"
                      //-             type="password"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="payload.password"
                                  :rules="[v => !!v || 'Preencha este campo']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.black--text Confirme a Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  light
                                  v-model="confirm_password"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      

                      div.mb-6.expande-horizontal.centraliza
                        v-flex(xs12)
                          v-list(color="transparent" nav class="px-0")
                            v-list-item.px-0
                              v-avatar.mr-2(color="#f2f2f2")
                                v-icon(:color="politica ? 'blue' : 'grey'") mdi-certificate-outline
                              v-list-item-content
                                v-list-item-title
                                  a.fonte.black--text(target="_blank" href="https://adm.scnrevolution.com.br/terms_policy/politica.pdf" style="text-decoration: none;") Política de Privacidade.
                                v-list-item-subtitle 
                                  span.fonte.grey--text Aceite a Política de Privacidade
                              v-list-item-action 
                                v-switch(class="pa-2" light :color="$theme.primary" outlined v-model="politica")
                            v-list-item.px-0
                              v-avatar.mr-2(color="#f2f2f2")
                                v-icon(:color="termos ? 'blue' : 'grey'") mdi-certificate-outline
                              v-list-item-content
                                v-list-item-title
                                  a.fonte.black--text(target="_blank" href="https://adm.scnrevolution.com.br/terms_policy/termo.pdf" style="text-decoration: none;") Termos de Uso.
                                v-list-item-subtitle 
                                  span.fonte.grey--text Aceite os Termos de uso
                              v-list-item-action 
                                v-switch(class="pa-2" light :color="$theme.primary" outlined v-model="termos")

                      div.mb-6.expande-horizontal.centraliza
                          v-btn.fonte(
                            :disabled="senhaOk && termos && politica && address_ok ? false : true"
                            x-large 
                            @click="startCamera"
                            color="#333"
                          ) 
                            span.fonte-bold.white--text Avançar
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===3")
            div.mb-6.expande-horizontal.centraliza.column.wrap
              v-flex(xs12)
                div.expande-horizontal
                  span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Selfie
              v-flex(xs12)
                Camera(
                  ref="camera" 
                  v-model="payload.selfie"
                  :path="`documents/selfie/${payload.cpf}`"
                )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                rounded
                v-if="payload.selfie"
                @click="goToDocumentsStep"
                color="#333"
              ) 
                span.fonte-bold.white--text Avançar
          v-flex(:class="{ 'pa-3': $vuetify.breakpoint.smAndDown, 'pa-3 px-12': $vuetify.breakpoint.mdAndUp }" xs12 md6 v-show="passo===4")
            div.mb-6.expande-horizontal.centraliza.column
              div.expande-horizontal
                span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.black--text Documentos
              document-files(
                v-model="payload.documents"
                @input="documentsOk($event)"
                :items="documents_requireds"
                :basePath="`documents/user/${payload.email}`"
              )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                x-large
                v-if="documents_ok"
                @click="valida_form"
                color="#333"
              ) 
                span.fonte-bold.white--text Concluir cadastro

                      
                      //- div.mb-6.flex.horizontal-expand.column
                      //-     v-btn.fonte(
                      //-         @click="$router.go(-1)"
                      //-         x-small
                      //-         text
                      //-         block
                      //-         light
                      //-         :color="$theme.primary"
                      //-     )
                      //-       span.black--text Já tem cadastro? Faça Login


</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Camera from "./Camera.vue";
import DocumentFiles from "./DocumentFiles.vue";
export default {
  props: ["sponsor_id"],
  components: {
    Camera,
    DocumentFiles
  },
  data() {
    return {
      passo: 1,
      modal: false,
      termos: false,
      politica: false,
      confirm_password: "",
      address_ok: false,
      // documents_requireds: [
      //   {
      //     name: 'Ato constitutivo ou Contrato Social',
      //     required: true
      //   },
      //   {
      //     name: 'Cartão CNPJ',
      //     required: true
      //   },
      //   {
      //     name: 'Comprovante de endereço da Empresa',
      //     required: true
      //   }
      // ],
      documents_requireds: [
        {
          name: "Habilitação ou RG",
          required: true
        },
        {
          name: "Comprovante de endereço",
          required: true
        }
      ],
      payload: {
        cpf: "",
        cep: "",
        address: {},
        documents: [],
        transaction_password: "",
        password: "",
        sponsor: {
          _id: "nosponsor"
        }
      },
      documents_ok: false,
      senhaOk: false
    };
  },
  computed: {
    ...mapGetters(["getSponsor"])
  },
  watch: {
    confirm_password(val) {
      if (val === this.payload.password) {
        this.senhaOk = true;
      } else {
        this.senhaOk = false;
      }
    },
    "payload.cep": function(val) {
      if (val.length === 9) {
        this.proccessAddress();
      }
    }
  },
  methods: {
    ...mapActions(["createUser", "verifySponsor", "verifyProfile", "getCep"]),
    proccessAddress() {
      this.getCep(this.payload.cep)
        .then(res => {
          this.payload.address = res;
          this.address_ok = true;
          setTimeout(() => {
            this.$refs.houseNumber.focus();
          }, 300);
        })
        .catch(() => {
          this.address_ok = false;
          this.payload.address = {};
        });
    },
    verifyCpf() {
      if (!this.payload.cpf && this.payload.cpf.length < 14) {
        return;
      } else {
        this.verifyProfile(this.payload.cpf)
          .then(result => {
            if (result.has) {
              sessionStorage.has = "1";
              sessionStorage.sponsor = this.sponsor_id;
              sessionStorage.username = this.payload.cpf;
              this.$router.push("/");
            } else {
              sessionStorage.removeItem("has");
              this.passo = 2;
            }
          })
          .catch(() => {
            this.passo = 2;
          });
      }
    },
    documentsOk(docs) {
      if (docs.length == this.documents_requireds.length) {
        this.documents_ok = true;
      }
    },
    startCamera() {
      this.passo = 3;
      setTimeout(async () => {
        await this.$refs.camera.startCamera();
      }, 300);
    },
    goToDocumentsStep() {
      this.passo = 4;
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.createUser({ ...this.payload, origin: "store" });
      }
    }
  },
  async created() {
    if (this.sponsor_id === "nosponsor") {
      this.passo = 1;
      this.payload.sponsor_id = "nosponsor";
    } else {
      this.payload.sponsor = await this.verifySponsor(this.sponsor_id);
      this.payload.sponsor_id = this.sponsor_id;
    }
  }
};
</script>

<style>
.page-bg {
  background: #fff; /* fallback for old browsers */
}
</style>
