<template>
  <v-card
    dark
    height="140"
    outlined
    color="#e5e5e5"
    class="expande-horizontal pa-3"
    style="flex-wrap: wrap; border-radius: 12px;"
  >
    <div class="expande-horizontal column" style="flex-wrap: nowrap;">
      <span style="font-size: 8pt;" class="fonte mb-2 grey--text"
        >Ferramentas</span
      >
      <div class="expande-horizontal">
        <span class="fonte-bold black--text" :style="`font-size: 26px;`">
          Ferramentas disponíveis</span
        >
      </div>
      <v-spacer></v-spacer>
      <div class="expande-horizontal">
        <v-btn
          :loading="loading"
          color="deep-purple"
          @click="getToken"
          rounded
        >
          <span class="fonte-bold white--text">SCNCommerce</span>
          <v-icon size="19">mdi-storefront</v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions(['createRequestCredential']),
    getToken() {
      this.loading = true;
      this.createRequestCredential()
        .then(res => {
          this.loading = false;
          window.open(res.data.url, "_blank");
        })
        .catch(err => {
          this.loading = false;
          this.$store.dispatch("createGlobalMessage", {
            type: "warning",
            message: err.response.data.message,
            timeout: 6000
          });
        });
    }
  }
};
</script>
